import { RootState } from "@redux/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "@api/index";
import TasksApi from "@api/tasks";

interface IInitialState {
  taskResources: TasksApi.IResource[];
  loading: boolean;
}

const initialState: IInitialState = {
  taskResources: [],
  loading: false,
};

export const getResource = createAsyncThunk(
  "tasks/getResource",
  async (payload: string) => {
    const { data } = await Api.tasks.getResource(payload);

    return data;
  }
);

export const updateResource = createAsyncThunk(
  "tasks/updateResource",
  async (payload: TasksApi.IUpdateResourceRequest) => {
    const { data } = await Api.tasks.updateResource(payload);

    return data;
  }
);

export const deleteResource = createAsyncThunk(
  "tasks/deleteResource",
  async (payload: number) => {
    const { data } = await Api.tasks.deleteResource(payload);

    return data;
  }
);

export const taskResourcesSlice = createSlice({
  name: "taskResources",
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResource.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        getResource.fulfilled,
        (_, action: PayloadAction<TasksApi.IResource[]>) => {
          return {
            taskResources: action.payload,
            loading: false,
          };
        }
      );
  },
});

export const { clear } = taskResourcesSlice.actions;

export const taskResourcesSelector = (state: RootState) => state.taskResources;

export default taskResourcesSlice.reducer;
