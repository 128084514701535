import React, { FC, useEffect } from "react";
import { DatePicker, Select, Modal, Button, Form } from "antd";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { getUsers, usersSelector } from "@redux/slices/users";
import { getProjects, projectsSelector } from "@redux/slices/projects";
import { postCreateReport } from "@redux/slices/timesheet";
import styles from "./DownloadTimeSheet.module.scss";

interface IDownloadTimeSheet {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const DownloadTimeSheet: FC<IDownloadTimeSheet> = ({
  isVisible,
  setIsVisible,
}) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const projects = useAppSelector(projectsSelector);
  const users = useAppSelector(usersSelector);

  useEffect(() => {
    if (isVisible) {
      dispatch(getUsers({ size: 1000, page: 0 }));
      dispatch(getProjects({ size: 1000, page: 0 }));
    }
  }, [dispatch, isVisible]);

  const handleOk = async () => {
    await form.validateFields();
    const {
      date: [dateFrom, dateTo],
      ...data
    } = form.getFieldsValue();

    dispatch(
      postCreateReport({
        ...data,
        dateFrom,
        dateTo,
      })
    );

    setIsVisible(false);
    form.resetFields();
  };

  const handleCancel = () => {
    setIsVisible(false);
    form.resetFields();
  };

  const onSelectAllUsers = () => {
    form.setFieldsValue({
      enterpriseId: users.content.map((el) => el.id),
    });
  };

  const onSelectAllProjects = () => {
    form.setFieldsValue({
      projectName: projects.content.map((el) => el.id),
    });
  };

  return (
    <Modal
      visible={isVisible}
      title="Download Timesheet Report"
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Close
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Export to excel
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="timesheetReport"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        autoComplete="off"
      >
        <Form.Item
          name="date"
          rules={[{ required: true, message: "Please select date interval" }]}
        >
          <DatePicker.RangePicker style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item>
          <div className={styles.wrapper}>
            <Form.Item
              noStyle
              name="projectName"
              rules={[{ required: true, message: "Please input project name" }]}
            >
              <Select
                className={styles.select}
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Project Name"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {projects.content.map((el) => (
                  <Select.Option key={el.id}>{el.projTitle}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Button className={styles.button} onClick={onSelectAllProjects}>
              Select All
            </Button>
          </div>
        </Form.Item>

        <Form.Item>
          <div className={styles.wrapper}>
            <Form.Item
              noStyle
              name="enterpriseId"
              rules={[
                {
                  required: true,
                  message: "Please select at least one resource enterprise id",
                },
              ]}
            >
              <Select
                className={styles.select}
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Resource Enterprise Ids"
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {users.content.map((el) => (
                  <Select.Option key={el.id}>{el.userFullName}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Button className={styles.button} onClick={onSelectAllUsers}>
              Select All
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DownloadTimeSheet;
