import { AxiosResponse } from "axios";
import { Moment } from "moment";
import { IPageableRequest, IPageableResponse } from "./api";
import { tasksUrl, myTasksUrl } from "./const";
import Http from "./https";

namespace TasksApi {
  export interface IActualTask {
    taskId: string;
    resourceId: number;
    projectName: string;
    projectId: string;
    taskName: string;
    resRemainWork: number;
    taskChargeable: boolean;
  }

  export interface IMyTask {
    actual: IActualTask[];
    outdated: IActualTask[];
  }

  export interface IGetRequest extends IPageableRequest {
    taskType: string;
    projectName?: string;
    userEnterpriseId?: string;
  }

  export interface IResource {
    resEnterpriseId: string;
    resTaskId: string;
    resBaseline: number;
    resActWork: number;
    id: number;
    resRemainWork: number;
    resourceStartDate: string;
    resourceEndDate: string;
  }

  export interface IAddResourceRequest {
    resEnterpriseId: string;
    resTaskId: string;
    resBaseline: number;
    resActWork: number;
    resourceStartDate: Moment;
    resourceEndDate: Moment;
  }

  export interface IUpdateResourceRequest {
    resourceId: number;
    data: IUpdateResourceData;
  }

  export interface IUpdateResourceData {
    resBaseline: number;
    resourceStartDate: Moment;
    resourceEndDate: Moment;
  }

  export interface IGetMyRequest {
    tsDateStart: string;
    tsDateFinish: string;
  }

  export interface IGeResponseContent {
    id: string;
    taskRemainWork: number;
    taskName: string;
    taskType: string;
    taskParentTaskId: string | null;
    taskDetails: string;
    taskBaseline: number;
    taskActWork: number | null;
    taskPredTaskId: number | null;
    taskSuccTaskId: number | null;
    taskBaseStartDate: string;
    taskBaseFinishDate: string | null;
    taskActStartDate: string | null;
    taskActFinishDate: string | null;
    project?: IProject;
    taskChargeCode: string;
    subTasks: any[] | null;
    resources: IResource[] | null;
  }

  export interface IProject {
    id: string;
    projTitle: string;
    projManager: string;
    projDetails: string;
  }

  export interface IGetResponse extends IPageableResponse {
    content: IGeResponseContent[];
  }

  export const getMy = async (
    params: IGetMyRequest
  ): Promise<AxiosResponse<IMyTask>> => {
    return await Http.Private.get(myTasksUrl, {
      params,
    });
  };

  export const get = async (
    data: IGetRequest
  ): Promise<AxiosResponse<IGetResponse>> => {
    return await Http.Private.get(tasksUrl, {
      params: data,
    });
  };

  export const getOne = async (
    taskId: string
  ): Promise<AxiosResponse<IGeResponseContent>> => {
    return await Http.Private.get(`${tasksUrl}/${taskId}`);
  };

  export const addResource = async (
    data: IAddResourceRequest
  ): Promise<AxiosResponse<IGeResponseContent>> => {
    return await Http.Private.put(`${tasksUrl}/tasks:add-resource`, data);
  };

  export const getResource = async (
    taskId: string
  ): Promise<AxiosResponse<IResource[]>> => {
    return await Http.Private.get(`${tasksUrl}/${taskId}:resources`);
  };

  export const updateResource = async (
    data: TasksApi.IUpdateResourceRequest
  ): Promise<AxiosResponse<IResource>> => {
    return await Http.Private.patch(
      `${tasksUrl}/${data.resourceId}:update-resource`,
      data.data
    );
  };

  export const deleteResource = async (resourceId: number) => {
    return await Http.Private.delete(
      `${tasksUrl}/${resourceId}:delete-resource`
    );
  };

  export const post = async (
    data: any
  ): Promise<AxiosResponse<IGeResponseContent>> => {
    return await Http.Private.post(tasksUrl, data);
  };

  export const put = async (
    data: any
  ): Promise<AxiosResponse<IGeResponseContent>> => {
    return await Http.Private.put(
      `${tasksUrl}/${data.taskParentTaskId}:update`,
      data
    );
  };

  export const patchApprover = async (
    data: any
  ): Promise<AxiosResponse<IGeResponseContent>> => {
    return await Http.Private.patch(
      `${tasksUrl}/${data.approver}:update/approver`,
      [data.taskId]
    );
  };

  export const del = async (data: string) => {
    return await Http.Private.delete(`${tasksUrl}/${data}`);
  };
}

export default TasksApi;
