import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ALL_PAGE, CURRENT_PAGE } from "@helpers/routes";
import Current from "./Current/Current";
import All from "./All/All";
import ErrorBoundary from "@components/ErrorBoundary/ErrorBoundary";

const MyTimesheets = () => (
  <>
    <Routes>
      <Route
        path={CURRENT_PAGE}
        element={
          <ErrorBoundary>
            <Current />
          </ErrorBoundary>
        }
      />
      <Route
        path={ALL_PAGE}
        element={
          <ErrorBoundary>
            <All />
          </ErrorBoundary>
        }
      />
      <Route path="*" element={<Navigate to={CURRENT_PAGE} replace />} />
    </Routes>
  </>
);

export default MyTimesheets;
