import { RootState } from "@redux/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import ProjectsApi from "@api/projects";
import { IPageableRequest } from "@api/api";
import Api from "@api/index";
import { defaultPageableContent } from "@api/const";

const initialState: ProjectsApi.IGetResponse = {
  ...defaultPageableContent,
};

export const getProjects = createAsyncThunk(
  "projects/getProjects",
  async (payload?: IPageableRequest) => {
    const { data } = await Api.projects.get(payload);

    return data;
  }
);

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      getProjects.fulfilled,
      (_, action: PayloadAction<ProjectsApi.IGetResponse>) => action.payload
    );
  },
});

export const { clear } = projectsSlice.actions;

export const projectsSelector = (state: RootState) => state.projects;

export default projectsSlice.reducer;
