import { RootState } from "@redux/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "@api/index";
import TasksApi from "@api/tasks";

const initialState: TasksApi.IMyTask = {
  actual: [],
  outdated: [],
};

export const getMyTasks = createAsyncThunk(
  "myTasks/getMyTasks",
  async (payload: TasksApi.IGetMyRequest) => {
    const { data } = await Api.tasks.getMy(payload);

    return data;
  }
);

export const myTasksSlice = createSlice({
  name: "myTasks",
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      getMyTasks.fulfilled,
      (_, action: PayloadAction<TasksApi.IMyTask>) => action.payload
    );
  },
});

export const { clear } = myTasksSlice.actions;

export const myTasksSelector = (state: RootState) => state.myTasks;

export default myTasksSlice.reducer;
