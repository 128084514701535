export function mapObjectToSearchParams<T extends object>(
  baseUrl: string,
  obj: T
): string {
  let result = baseUrl;

  const entries = Object.entries(obj).filter(([_, value]) => {
    // Если value - пустой массив
    if (Array.isArray(value) && !value.length) return false;

    if (value === undefined) return false;

    return true;
  });

  if (!entries.length) return result;

  entries.forEach(([key, value], index) => {
    result += index ? "&" : "?";
    result += `${key}=`;
    result += Array.isArray(value) ? value.join(`&${key}=`) : `${value}`;
  });

  return result;
}
