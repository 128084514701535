import React, { FC, useEffect, useState } from "react";
import { Select, Modal, Button, Form, Input, DatePicker } from "antd";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { getUsers, usersSelector } from "@redux/slices/users";
import { getTasks, putAddResource } from "@redux/slices/tasks";
import moment, { Moment } from "moment";
import {
  getResource,
  taskResourcesSelector,
  updateResource,
} from "@redux/slices/taskResources";
import TasksApi from "@api/tasks";

export interface IAddResourceModalState {
  isVisible: boolean;
  resourceId?: number;
  taskId?: string;
}

interface IAddResource {
  addResourceModal: IAddResourceModalState;
  setAddResourceModal: React.Dispatch<
    React.SetStateAction<IAddResourceModalState>
  >;
}

interface IAddResourceForm {
  resEnterpriseId: string;
  resTaskId: string;
  resBaseline: number;
  resActWork: number;
  resDates: Moment[];
}

const AddResource: FC<IAddResource> = ({
  addResourceModal,
  setAddResourceModal,
}) => {
  const [form] = Form.useForm<IAddResourceForm>();
  const dispatch = useAppDispatch();
  const { content } = useAppSelector(usersSelector);
  const [data, setData] = useState(content);
  const { taskResources } = useAppSelector(taskResourcesSelector);

  useEffect(() => {
    if (addResourceModal.isVisible) {
      dispatch(getUsers({ size: 1000, page: 0 }));
    }
    if (addResourceModal.resourceId && addResourceModal.taskId) {
      dispatch(getResource(addResourceModal.taskId));
    }
  }, [dispatch, addResourceModal]);

  useEffect(() => {
    setData(content);
  }, [content]);

  useEffect(() => {
    if (addResourceModal.resourceId) {
      const resourceData = taskResources.find(
        (el: TasksApi.IResource) => el.id === addResourceModal.resourceId
      );

      form.setFieldsValue({
        resEnterpriseId: resourceData?.resEnterpriseId,
        resTaskId: resourceData?.resTaskId,
        resBaseline: resourceData?.resBaseline,
        resActWork: resourceData?.resActWork,
        resDates: [
          moment(resourceData?.resourceStartDate),
          moment(resourceData?.resourceEndDate),
        ],
      });
    }
  }, [taskResources, addResourceModal.resourceId, form]);

  const handleOk = async () => {
    if (addResourceModal.taskId) {
      const {
        resDates: [resourceStartDate, resourceEndDate],
        ...reqData
      } = form.getFieldsValue();

      if (addResourceModal.resourceId) {
        await dispatch(
          updateResource({
            resourceId: addResourceModal.resourceId,
            data: {
              resBaseline: reqData.resBaseline,
              resourceStartDate,
              resourceEndDate,
            },
          })
        );
      } else {
        await dispatch(
          putAddResource({
            ...reqData,
            resTaskId: addResourceModal.taskId,
            resourceStartDate,
            resourceEndDate,
          })
        );
      }

      setAddResourceModal({
        isVisible: false,
        taskId: "",
      });

      form.resetFields();

      dispatch(
        getTasks({
          taskType: "TASK",
          page: 0,
          size: 50,
        })
      );
    }
  };

  const handleCancel = () => {
    setAddResourceModal({
      isVisible: false,
      taskId: "",
    });
    form.resetFields();
  };

  const onSearch = (value: string) => {
    setData(content.filter((el) => el.id.includes(value)));
  };

  return (
    <Modal
      visible={addResourceModal.isVisible}
      title={addResourceModal.resourceId ? "Edit resource" : "Add Resource"}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Close
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          {addResourceModal.resourceId ? "Edit resource" : "Add Resource"}
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="addResource"
        initialValues={{ remember: true }}
        autoComplete="off"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          columnGap: 40,
        }}
      >
        <Form.Item
          name="resEnterpriseId"
          rules={[
            { required: true, message: "Please select user enterprise ID" },
          ]}
        >
          <Select
            showSearch
            placeholder="Resource Enterprise ID"
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            bordered={false}
            onSearch={onSearch}
            notFoundContent={null}
            className="customSelect"
          >
            {data.map((el) => (
              <Select.Option key={el.id} value={el.id}>
                {el.id}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="resBaseline"
          rules={[
            { required: true, message: "Please input resource baseline" },
          ]}
        >
          <Input
            bordered={false}
            className="customInput"
            placeholder="Resource Baseline"
          />
        </Form.Item>
        <Form.Item
          name="resActWork"
          rules={[
            { required: true, message: "Please input resource actual work" },
          ]}
        >
          <Input
            bordered={false}
            className="customInput"
            placeholder="Resource Actual Work"
          />
        </Form.Item>
        <Form.Item name="resDates">
          <DatePicker.RangePicker
            className="customDataPicker"
            placeholder={["Start Date", "End Date"]}
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddResource;
