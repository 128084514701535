import { useEffect, useMemo, useState, useCallback } from "react";
import { PageHeader, Button, notification } from "antd";
import { pageTitles } from "@helpers/const";
import Hooks from "@helpers/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import styles from "./AnalysisTool.module.scss";
import AnalysisTable, { ITableData } from "./AnalysisTable/AnalysisTable";
import CreateNewAnalysis from "@components/CreateNewAnalysis/CreateNewAnalysis";
import {
  IAnalysisData,
  ICurrentDate,
  IDrawerCreate,
} from "./AnalysisTool.types";
import AnalysisSearch from "./AnalysisSearch/AnalysisSearch";
import moment from "moment";
import Loader from "@components/Loader/Loader";
import {
  getAnalysis,
  analysisSelector,
  patchAnalysis,
  approveAnalysis,
  deleteAnalysis,
} from "@redux/slices/analysis";
import _ from "lodash";
import { authSelector } from "@redux/slices/auth";
import { IPageableRequest } from "@api/api";
import Helpers from "@helpers/utils";
import Api from "@api/index";
import analysisApi from "@api/analysis";

export const defaultCreateNewAnalysisState = {
  isEdit: false,
  isVisible: false,
};

const getRejectedTaskDescription = ({
  date,
  projectName,
  taskName,
  description,
}: analysisApi.IGetResponseContent) =>
  `${date} > ${projectName} > ${taskName} > ${description}`;

const AnalysisTool = () => {
  const Portal = Hooks.usePortal(document.getElementById("pageHeader"));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [filters, setFilters] = useState({});
  const [selectedRows, setSelectedRows] = useState<IAnalysisData>([]);
  const [createNewAnalysisState, setCreateNewAnalysisState] =
    useState<IDrawerCreate>(defaultCreateNewAnalysisState);
  const [pagination, setPagination] = useState<IPageableRequest>({
    page: 1,
    size: 50,
  });
  const { loading } = useAppSelector(analysisSelector);
  const { id, userAccessLevel } = useAppSelector(authSelector);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const defaultFilters = useMemo(() => ({ analysisExecutor: id }), [id]);
  const [date, setDate] = useState<ICurrentDate>({
    dateFrom: search
      ? moment(params.get("dateFrom"))
      : moment().startOf("week"),
    dateTo: search ? moment(params.get("dateTo")) : moment().endOf("week"),
  });

  const { analysis } = useAppSelector(analysisSelector);
  const { userFullName } = useAppSelector(authSelector);

  // Данный useEffect нужен для того, чтобы подсветить задачи со статусом "Reject".
  useEffect(() => {
    Api.analysis
      .get({
        startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        page: 0,
        size: 100,
        analysisExecutor: userFullName,
      })
      .then(
        (response) => {
          const rejectedTasks = response.data.content.filter(
            ({ status }) => status === "REJECTED"
          );

          if (rejectedTasks.length === 1) {
            notification.warn({
              message: `Rejected task detected: ${getRejectedTaskDescription(
                rejectedTasks[0]
              )}`,
            });
          }

          if (rejectedTasks.length > 1) {
            notification.warn({
              message: "Rejected tasks detected:",
              description: (
                <ul>
                  {rejectedTasks.map((rejectedTask, index) => (
                    <li key={index}>
                      {getRejectedTaskDescription(rejectedTask)}
                    </li>
                  ))}
                </ul>
              ),
            });
          }
        },
        () => {}
      );
  }, [userFullName]);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 100,
      sorter: (a: ITableData, b: ITableData) => Helpers.compare(a.date, b.date),
    },
    {
      title: "Project",
      dataIndex: "projectName",
      key: "project",
      width: 100,
      sorter: (a: ITableData, b: ITableData) =>
        Helpers.compare(a.projectName, b.projectName),
    },
    {
      title: "Task",
      dataIndex: "taskName",
      key: "task",
      width: 100,
      sorter: (a: ITableData, b: ITableData) =>
        Helpers.compare(a.taskName, b.taskName),
    },
    {
      title: "Action type",
      dataIndex: "actionTypeName",
      key: "actionTypeName",
      width: 200,
      sorter: (a: ITableData, b: ITableData) =>
        Helpers.compare(a.actionTypeName, b.actionTypeName),
    },
    {
      title: "Effort",
      dataIndex: "effort",
      key: "effort",
      width: 60,
      sorter: (a: ITableData, b: ITableData) =>
        Helpers.compare(a.effort, b.effort),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 200,
      sorter: (a: ITableData, b: ITableData) =>
        Helpers.compare(a.description, b.description),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      sorter: (a: ITableData, b: ITableData) =>
        Helpers.compare(a.status, b.status),
    },
    {
      title: "Reject comment",
      dataIndex: "rejectComment",
      key: "rejectComment",
      width: 200,
      sorter: (a: ITableData, b: ITableData) =>
        Helpers.compare(a.rejectComment, b.rejectComment),
    },
  ];

  const update = useCallback(
    (page: number, size: number) => {
      const requestDate = _.cloneDeep(date);
      dispatch(
        getAnalysis({
          startDate: requestDate.dateFrom.format("YYYY-MM-DD"),
          endDate: requestDate.dateTo.format("YYYY-MM-DD"),
          page: page - 1,
          size,
          ...defaultFilters,
          ...filters,
        })
      );
    },
    [date, defaultFilters, dispatch, filters]
  );

  useEffect(() => {
    if (id) {
      const requestDate = _.cloneDeep(date);
      dispatch(
        getAnalysis({
          startDate: requestDate.dateFrom.format("YYYY-MM-DD"),
          endDate: requestDate.dateTo.format("YYYY-MM-DD"),
          page: 0,
          size: 50,
          ...defaultFilters,
          ...filters,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, date]);

  const sendToApprove = useCallback(async () => {
    if (selectedRows.find((item) => item.status === "APPROVED")) {
      notification["error"]({
        message: "Ошибка",
        description: "Analysis was approved",
        duration: 3,
      });
    } else if (selectedRows.find((item) => item.status === "WAITING_APPROVE")) {
      notification["error"]({
        message: "Ошибка",
        description: "Analysis waiting approve",
        duration: 3,
      });
    } else {
      const toApprove = selectedRows.map((item) => {
        return item.analysisRecordId;
      });

      setSelectedRows([]);

      const requestDate = _.cloneDeep(date);
      await dispatch(
        patchAnalysis({
          data: toApprove,
          dates: {
            dateStart: requestDate.dateFrom.format("YYYY-MM-DD"),
            dateFinish: requestDate.dateTo.format("YYYY-MM-DD"),
          },
        })
      );

      update(pagination.page, pagination.size);
    }
  }, [dispatch, pagination, selectedRows, update, date]);

  const onCreate = () => {
    setCreateNewAnalysisState({
      isVisible: true,
      isEdit: false,
    });
  };

  const onEdit = () => {
    setCreateNewAnalysisState({
      isVisible: true,
      isEdit: true,
    });
  };

  const onDelete = useCallback(async () => {
    const requestDate = _.cloneDeep(date);
    await dispatch(
      deleteAnalysis({
        items: selectedRows.map((el) => el.analysisRecordId),
        dates: {
          dateStart: requestDate.dateFrom.format("YYYY-MM-DD"),
          dateFinish: requestDate.dateTo.format("YYYY-MM-DD"),
        },
      })
    );
    update(pagination.page, pagination.size);
  }, [dispatch, pagination, selectedRows, update, date]);

  const onApprove = useCallback(async () => {
    const approveContent = selectedRows.map((item) => {
      return item.analysisRecordId;
    });
    const requestDate = _.cloneDeep(date);
    const approveData = {
      dates: {
        dateStart: requestDate.dateFrom.format("YYYY-MM-DD"),
        dateFinish: requestDate.dateTo.format("YYYY-MM-DD"),
      },
      content: approveContent,
    };
    await dispatch(approveAnalysis(approveData));

    update(pagination.page, pagination.size);
    setSelectedRows([]);
  }, [dispatch, pagination, selectedRows, date, update]);

  return (
    <>
      {loading && <Loader isAbsolute />}
      <Portal>
        <PageHeader
          className={styles.header}
          onBack={() => navigate(-1)}
          title={pageTitles[pathname as keyof typeof pageTitles]}
        />
      </Portal>

      <AnalysisSearch
        pagination={pagination}
        filters={filters}
        setFilters={setFilters}
        date={date}
        setDate={setDate}
        defaultFilters={defaultFilters}
        analysis={analysis}
      />
      <div className={styles.table}>
        <AnalysisTable
          pagination={pagination}
          setPagination={setPagination}
          update={update}
          columns={columns}
          analysis={analysis}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
        ></AnalysisTable>
      </div>
      <div className={styles.analysisBtns}>
        <Button type="primary" key="create" onClick={onCreate}>
          Create new
        </Button>
        <Button
          type="default"
          danger
          disabled={!selectedRows.length}
          key="edit"
          onClick={onDelete}
        >
          Delete
        </Button>
        <Button
          type="default"
          disabled={!selectedRows.length}
          key="edit"
          onClick={onEdit}
        >
          Edit
        </Button>
        <Button
          key="send"
          onClick={sendToApprove}
          disabled={!selectedRows.length}
        >
          Send to approve
        </Button>
        {userAccessLevel === "ADMIN" && (
          <Button
            type="primary"
            key="create"
            disabled={selectedRows.length ? false : true}
            onClick={onApprove}
          >
            Approve several
          </Button>
        )}
      </div>
      <CreateNewAnalysis
        pagination={pagination}
        dates={date}
        filters={filters}
        createNewAnalysisState={createNewAnalysisState}
        setCreateNewAnalysisState={setCreateNewAnalysisState}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        defaultFilters={defaultFilters}
      />
    </>
  );
};

export default AnalysisTool;
