import { notification } from "antd";
import _ from "lodash";

namespace Helpers {
  type NotificationType = "success" | "info" | "warning" | "error";

  export const openNotificationWithIcon = (
    type: NotificationType,
    text: string,
    errorType: string
  ) => {
    notification[type]({
      message: _.upperFirst(type),
      description: errorType === "CUSTOM" ? text : "Something went wrong",
    });
  };

  export const compare = (a: any, b: any) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };
}

export default Helpers;
