import { AxiosResponse } from "axios";
import { IPageableRequest, IPageableResponse } from "./api";
import { createReportUrl, currentTimesheetUrl, timesheetUrl } from "./const";
import Http from "./https";
import TasksApi from "./tasks";

namespace TimesheetApi {
  export interface ICreateReportRequest {
    projectName: string[];
    enterpriseId: string[];
    dateFrom: string;
    dateTo: string;
  }

  export interface IGetRequest extends IPageableRequest, IGetCurrentRequest {
    timesheetStatus?: string;
  }

  export interface IGetCurrentRequest {
    enterpriseId?: string;
    dateFrom?: string;
    dateTo?: string;
  }

  export interface IGetResponseContent {
    id: string;
    timesheetStatus: string;
    timesheetDateStart: string;
    timesheetDateEnd: string;
    timesheetDocVersion: number;
    timesheetEnterpriseId: string;
    dayActivities: IDayActivities[];
    tasks: TasksApi.IMyTask;
  }

  export interface IGetCurrentResponse {
    id: string;
    timesheetStatus: string;
    timesheetDateStart: string;
    timesheetDateEnd: string;
    timesheetDocVersion: number;
    timesheetEnterpriseId: string;
    dayActivities: IDayActivities[];
    tasks: TasksApi.IMyTask;
  }

  export interface IGetResponse extends IPageableResponse {
    content: IGetResponseContent[];
  }

  export interface IDayActivities {
    id?: number;
    activResourceId: number;
    activActWork: number;
    activDate: string;
    activWeekday: string;
    activEnterpriseId?: string;
    activRejectDetails?: string | null;
    activTimesheet?: string;
    taskId?: string;
  }

  export interface ISaveRequest {
    timesheetDocVersion: number;
    dayActivities: IDayActivities[];
    timesheetId: string;
  }

  export interface IPostRequest {
    timesheetStatus: "DRAFT";
    timesheetDateStart: string;
    timesheetDateEnd: string;
    timesheetDocVersion: number;
    timesheetEnterpriseId: string;
    dayActivities: [];
    previousTimesheet: null;
  }

  export const createReport = async (
    data: ICreateReportRequest
  ): Promise<AxiosResponse<Blob>> => {
    return await Http.Private.post(createReportUrl, data, {
      responseType: "blob",
    });
  };

  export const getCurrent = async (
    data?: IGetCurrentRequest
  ): Promise<AxiosResponse<any, any>> => {
    return await Http.Private.get(currentTimesheetUrl, {
      params: data,
    });
  };

  export const get = async (
    data?: IGetRequest
  ): Promise<AxiosResponse<IGetResponse>> => {
    return await Http.Private.get(timesheetUrl, {
      params: data,
    });
  };

  export const post = async (data: IPostRequest) => {
    return await Http.Private.post(timesheetUrl, data);
  };

  export const save = async (
    data: ISaveRequest
  ): Promise<AxiosResponse<IGetResponseContent>> => {
    const { timesheetId, ...body } = data;
    return await Http.Private.put(`${timesheetUrl}/${timesheetId}:save`, body);
  };

  export const submit = async (data: ISaveRequest) => {
    const { timesheetId, ...body } = data;
    return await Http.Private.put(
      `${timesheetUrl}/${timesheetId}:submit`,
      body
    );
  };

  export const recall = async (id: string) => {
    return await Http.Private.put(`${timesheetUrl}/${id}:recall`);
  };
}

export default TimesheetApi;
