import React, { FC, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import type { MenuProps } from "antd";
import { Layout, Typography, Menu } from "antd";
import {
  LeftOutlined,
  RightOutlined,
  SettingOutlined,
  FolderOpenOutlined,
  FileTextOutlined,
  DownloadOutlined,
  FileAddOutlined,
  FormOutlined,
  CarryOutOutlined,
  DeliveredProcedureOutlined,
} from "@ant-design/icons";
import { authSelector } from "@redux/slices/auth";
import { useAppSelector } from "@redux/hooks";
import DownloadTimeSheet from "@components/DownloadTimesheet/DownloadTimeSheet";
import {
  ALL_TIMESHEETS_PAGE,
  CURRENT_TIMESHEETS_PAGE,
  TASK_CREATION_PAGE,
  ANALYSIS_TOOL_PAGE,
  ANALYSIS_APPROVE_PAGE,
  EXTERNAL_TOOL_PAGE,
} from "@helpers/routes";
import ActAsDelegate from "@components/ActAsDelegate/ActAsDelegate";
import CreateResource from "@components/CreateResource/CreateResource";
import { siderConfig } from "@helpers/const";
import styles from "./Sider.module.scss";
import DownloadAtReport from "@components/DownloadAtReport/DownloadAtReport";

interface SiderProps {
  children:
    | string
    | number
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

export interface ICreateResourceModal {
  isVisible: boolean;
  isEdit: boolean;
}

type MenuItem = Required<MenuProps>["items"][number];

const Sider: FC<SiderProps> = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isAuth, userAccessLevel } = useAppSelector(authSelector);
  const [collapsed, setCollapsed] = useState(true);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [reportModalVisible, setReportModalVisible] = useState(false);
  const [atReportModalVisible, setAtReportModalVisible] = useState(false);
  const [delegateModalVisible, setDelegateModalVisible] = useState(false);
  const [createResourceModal, setCreateResourceModal] =
    useState<ICreateResourceModal>({
      isVisible: false,
      isEdit: false,
    });

  const getItem = (
    visible: boolean,
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem => {
    if (visible) {
      return {
        key,
        icon,
        children,
        label,
        type,
      } as MenuItem;
    }
    return null;
  };

  useEffect(() => {
    if (siderConfig[pathname]) {
      setSelectedKeys([pathname]);
    }
  }, [pathname]);

  const items: MenuItem[] = [
    getItem(true, "Current", CURRENT_TIMESHEETS_PAGE, <FileTextOutlined />),
    getItem(
      true,
      "All Timesheets",
      ALL_TIMESHEETS_PAGE,
      <FolderOpenOutlined />
    ),

    getItem(
      userAccessLevel === "ADMIN",
      "Resources",
      "sub1",
      <SettingOutlined />,
      [
        getItem(true, "Act as Delegate", "ActAsDelegate"),
        getItem(true, "Edit resource", "EditResource"),
        getItem(true, "Add Resource", "CreateResource"),
      ]
    ),

    getItem(
      userAccessLevel === "ADMIN",
      "Download timesheet report",
      "sub2",
      <DownloadOutlined />,
      [
        getItem(true, "Download timesheet report", "downloadReport"),
        getItem(true, "Download analysis report", "downloadAtReport"),
      ]
    ),

    getItem(
      userAccessLevel === "ADMIN",
      "Task Creation",
      TASK_CREATION_PAGE,
      <FileAddOutlined />
    ),

    getItem(true, "Analysis tool", ANALYSIS_TOOL_PAGE, <FormOutlined />),

    getItem(
      userAccessLevel === "ADMIN",
      "Analysis approve",
      ANALYSIS_APPROVE_PAGE,
      <CarryOutOutlined />
    ),

    getItem(
      true,
      "External tool",
      EXTERNAL_TOOL_PAGE,
      <DeliveredProcedureOutlined />
    ),
  ];

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const goTo = ({ key }: { key: string }) => {
    switch (key) {
      case "downloadReport": {
        setReportModalVisible(true);
        break;
      }
      case "downloadAtReport": {
        setAtReportModalVisible(true);
        break;
      }
      case "ActAsDelegate": {
        setDelegateModalVisible(true);
        break;
      }
      case "CreateResource": {
        setCreateResourceModal({
          isVisible: true,
          isEdit: false,
        });
        break;
      }
      case "EditResource": {
        setCreateResourceModal({
          isVisible: true,
          isEdit: true,
        });
        break;
      }
      default: {
        navigate(key);
        break;
      }
    }
  };

  return (
    <Layout>
      {isAuth && (
        <>
          <Layout.Sider
            trigger={null}
            collapsedWidth={50}
            onCollapse={() => setCollapsed(!collapsed)}
            collapsible
            collapsed={collapsed}
            className={styles.sider}
          >
            <div onClick={toggle} className={styles["sider-button"]}>
              {collapsed ? <RightOutlined /> : <LeftOutlined />}
            </div>
            {!collapsed && (
              <Typography.Title className={styles.title} level={5}>
                MY TIMESHEETS
              </Typography.Title>
            )}
            <Menu
              onClick={goTo}
              defaultSelectedKeys={[CURRENT_TIMESHEETS_PAGE]}
              mode="inline"
              theme="dark"
              inlineCollapsed={collapsed}
              items={items}
              selectedKeys={selectedKeys}
            />
          </Layout.Sider>
          <DownloadTimeSheet
            isVisible={reportModalVisible}
            setIsVisible={setReportModalVisible}
          />
          <DownloadAtReport
            isVisible={atReportModalVisible}
            setIsVisible={setAtReportModalVisible}
          />
          <ActAsDelegate
            isVisible={delegateModalVisible}
            setIsVisible={setDelegateModalVisible}
          />
          <CreateResource
            createResourceModal={createResourceModal}
            setCreateResourceModal={setCreateResourceModal}
          />
        </>
      )}
      <div className={styles.wrapper}>
        <div id="pageHeader" />
        <Layout.Content
          style={
            pathname === TASK_CREATION_PAGE
              ? {
                  padding: 0,
                }
              : {}
          }
          className={styles.content}
        >
          {children}
        </Layout.Content>
      </div>
    </Layout>
  );
};

export default Sider;
