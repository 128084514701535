import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { setDelegatedUser } from "@redux/slices/delegatedUser";
import { getUsers, usersSelector } from "@redux/slices/users";
import { Button, Form, Select, Modal } from "antd";
import React, { FC, useCallback, useEffect, useState } from "react";

interface IActAsDelegate {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ActAsDelegate: FC<IActAsDelegate> = ({ isVisible, setIsVisible }) => {
  const { content } = useAppSelector(usersSelector);
  const dispatch = useAppDispatch();
  const [data, setData] = useState(content);
  const [form] = Form.useForm();

  useEffect(() => {
    if (isVisible) {
      dispatch(getUsers({ size: 1000, page: 0 }));
    }
  }, [dispatch, isVisible]);

  useEffect(() => setData(content), [content]);

  const handleOk = async () => {
    await form.validateFields();
    setIsVisible(false);
    const { id } = form.getFieldsValue();
    const [{ userFullName }] = data.filter((el) => el.id === id);
    dispatch(
      setDelegatedUser({
        id,
        userFullName,
      })
    );
    form.resetFields();
  };

  const handleCancel = () => {
    setIsVisible(false);
    form.resetFields();
  };

  const handleSearch = useCallback(
    (value: string) => {
      setData(content.filter((el) => el.userFullName.includes(value)));
    },
    [content]
  );

  return (
    <>
      <Modal
        visible={isVisible}
        title="Act As Delegate"
        onCancel={handleCancel}
        onOk={handleOk}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Close
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Ok
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="actAsDelegate"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Form.Item
            name="id"
            rules={[{ required: true, message: "Please input Enterprise ID" }]}
          >
            <Select
              showSearch
              placeholder="Input Enterprise ID"
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={handleSearch}
              notFoundContent={null}
            >
              {data?.map((el) => (
                <Select.Option key={el.id} value={el.id}>
                  {el.userFullName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ActAsDelegate;
