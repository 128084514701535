import { defaultPageableContent } from "@api/const";
import { RootState } from "@redux/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import UsersApi from "@api/users";
import { IPageableRequest } from "@api/api";
import Api from "@api/index";

const initialState: UsersApi.IGetResponse = {
  ...defaultPageableContent,
};

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (payload?: IPageableRequest) => {
    const { data } = await Api.users.get(payload);

    return data;
  }
);

export const postUsers = createAsyncThunk(
  "users/postUsers",
  async (payload: UsersApi.IPostRequest) => {
    const { data } = await Api.users.post(payload);

    return data;
  }
);

export const putUsers = createAsyncThunk(
  "users/putUsers",
  async (payload: UsersApi.IPostRequest) => {
    const { data } = await Api.users.put(payload);

    return data;
  }
);

export const deleteUsers = createAsyncThunk(
  "users/deleteUsers",
  async (payload: string) => {
    const { data } = await Api.users.deleteUserReq(payload);

    return data;
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      getUsers.fulfilled,
      (_, action: PayloadAction<UsersApi.IGetResponse>) => action.payload
    );
  },
});

export const { clear } = usersSlice.actions;

export const usersSelector = (state: RootState) => state.users;

export default usersSlice.reducer;
