import React, { useCallback, useEffect, useState, Key } from "react";
import { Button, Popconfirm } from "antd";
import { useAppDispatch } from "@redux/hooks";
import { deleteTasks, getTasks } from "@redux/slices/tasks";
import styles from "./TaskCreation.module.scss";
import CreateNewTask from "../../components/CreateNewTask/CreateNewTask";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import TaskTable from "./TaskTable/TaskTable";
import TaskSearch from "./TaskSearch/TaskSearch";

export interface ICreateNewTaskState {
  isVisible: boolean;
  taskType: string;
  isEdit: boolean;
  taskId?: string;
}

export const defaultCreateNewTaskState = {
  isVisible: false,
  taskType: "TASK",
  taskId: "",
  isEdit: false,
};

const TaskCreation = () => {
  const dispatch = useAppDispatch();
  const [createNewTaskState, setCreateNewTaskState] =
    useState<ICreateNewTaskState>(defaultCreateNewTaskState);
  const [selectedTasks, setSelectedTasks] = useState<React.Key[]>([]);

  useEffect(() => {
    dispatch(
      getTasks({
        taskType: "TASK",
        page: 0,
        size: 50,
      })
    );
  }, [dispatch]);

  const onCreateNewTask = () => {
    setCreateNewTaskState({
      isVisible: true,
      taskType: "TASK",
      isEdit: false,
    });
  };

  const onDeleteClick = useCallback(async () => {
    const reqForDelete = selectedTasks.map((el) =>
      dispatch(deleteTasks(el.toString()))
    );
    await Promise.all(reqForDelete);
    dispatch(
      getTasks({
        taskType: "TASK",
        page: 0,
        size: 50,
      })
    );
    setSelectedTasks([]);
  }, [selectedTasks, dispatch]);

  const onEditTask = (selected: Key[]) => {
    setCreateNewTaskState({
      isVisible: true,
      taskType: "TASK",
      isEdit: true,
      taskId: selected ? `${selected[0]}` : "",
    });
    setSelectedTasks([]);
  };

  return (
    <div className={styles.taskCreation}>
      <div className={styles.taskCreationTop}>
        <span className={styles.title}>Task</span>
        <div className={styles.actions}>
          <Popconfirm
            title="Are you sure?"
            onConfirm={onDeleteClick}
            okText="Yes"
            cancelText="No"
          >
            <Button
              icon={<DeleteOutlined />}
              disabled={!selectedTasks.length}
              danger
            >
              Delete
            </Button>
          </Popconfirm>
          <Button
            icon={<EditOutlined />}
            type="default"
            disabled={!selectedTasks.length}
            onClick={() => onEditTask(selectedTasks)}
          >
            Edit
          </Button>
          <Button type="primary" onClick={onCreateNewTask}>
            + New task
          </Button>
        </div>
      </div>
      <div className={styles.wrapper}>
        <TaskSearch />
        <TaskTable
          selectedRowKeys={selectedTasks}
          setSelectedRowKeys={setSelectedTasks}
        />
      </div>
      <CreateNewTask
        createNewTaskState={createNewTaskState}
        setCreateNewTaskState={setCreateNewTaskState}
      />
    </div>
  );
};

export default TaskCreation;
