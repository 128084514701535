import React from "react";
import { Form, Input, Button, Checkbox, Typography } from "antd";
import styles from "./Auth.module.scss";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { authSelector, signIn } from "@redux/slices/auth";
import Loader from "@components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { CURRENT_TIMESHEETS_PAGE } from "@helpers/routes";

const { Title } = Typography;

const Auth = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading } = useAppSelector(authSelector);

  const onFinish = async (values: any) => {
    const { email, password } = values;
    await dispatch(
      signIn({
        email,
        password,
      })
    );
    navigate(CURRENT_TIMESHEETS_PAGE);
  };

  return isLoading ? (
    <Loader isAbsolute />
  ) : (
    <Form
      name="basic"
      layout="vertical"
      className={styles.form}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item>
        <Title level={3} className={styles.title}>
          Timesheet
        </Title>
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: "Please input your Email!" }]}
      >
        <Input placeholder="Email" />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>

      <Form.Item name="remember" valuePropName="checked">
        <Checkbox>Remember me</Checkbox>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Log In
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Auth;
