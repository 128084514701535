import { RootState } from "@redux/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "@api/index";
import externalApi from "@api/external";
import { defaultPageableContent } from "@api/const";

const initialState: IInitial = {
  external: defaultPageableContent,
  loading: false,
};

interface IInitial {
  external: externalApi.IGetResponse;
  loading: boolean;
}

export const getExternal = createAsyncThunk(
  "external/getExternal",
  async (payload: externalApi.IGetRequest) => {
    const { data } = await Api.analysis.get(payload);

    return data;
  }
);

export const postExternal = createAsyncThunk(
  "external/postExternal",
  async (payload: externalApi.IPostRequest) => {
    const { data } = await Api.external.post(payload);

    return data;
  }
);

export const deleteExternal = createAsyncThunk(
  "external/deleteExternal",
  async (payload: externalApi.IDelRequest) => {
    const { data } = await Api.external.deleteItems(payload);

    return data;
  }
);

export const updateExternal = createAsyncThunk(
  "external/updateExternal",
  async (payload: externalApi.IPostRequest) => {
    await Api.external.putUpdate(payload);
  }
);

export const externalSlice = createSlice({
  name: "external",
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExternal.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        getExternal.fulfilled,
        (state, action: PayloadAction<externalApi.IGetResponse | null>) => {
          if (action.payload) {
            return {
              external: action.payload,
              loading: false,
            };
          }
          return state;
        }
      );
  },
});

export const { clear } = externalSlice.actions;

export const externalSelector = (state: RootState) => state.external;

export default externalSlice.reducer;
