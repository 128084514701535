import { ClockCircleOutlined } from "@ant-design/icons";
import analysisApi from "@api/analysis";
import { IPageableRequest } from "@api/api";
import Hooks from "@helpers/hooks";
import { Pagination, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FC, useEffect, useState } from "react";
import { IAnalysisData } from "../AnalysisTool.types";
import styles from "./AnalysisTable.module.scss";

export interface IAnalysisTable {
  pagination: IPageableRequest;
  setPagination: React.Dispatch<React.SetStateAction<IPageableRequest>>;
  columns: ColumnsType<ITableData>;
  selectedRows: IAnalysisData;
  update: (page: number, size: number) => void;
  setSelectedRows: React.Dispatch<React.SetStateAction<IAnalysisData>>;
  analysis: analysisApi.IGetResponse;
}

export interface ITableData {
  key: string;
  analysisRecordId: string;
  actionTypeName: string;
  analysisExecutor: string;
  analysisApprover: string;
  date: string;
  description: string;
  effort: number;
  projectName: string;
  rejectComment: string | null;
  status: string;
  taskName: string;
}

const AnalysisTable: FC<IAnalysisTable> = ({
  pagination,
  columns,
  analysis,
  setPagination,
  update,
  selectedRows,
  setSelectedRows,
}) => {
  const { height } = Hooks.useWindowSize();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSource, setDataSource] = useState<ITableData[]>([]);
  const [total, setTotal] = useState(0);
  const onSelectChange = (newSelectedRowKeys: any, newSelectedRows: any) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(newSelectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    setTotal(0);
    setDataSource(
      analysis.content.map((el) => {
        setTotal((state) => state + el.effort);
        return {
          ...el,
          key: el.analysisRecordId,
          actionTypeName: el.actionType.actionTypeFullName,
        };
      })
    );
  }, [analysis, setDataSource]);

  useEffect(() => {
    if (!selectedRows.length) {
      setSelectedRowKeys([]);
    }
  }, [selectedRows]);

  const onPageChange = (page: number, size: number) => {
    setPagination({ page, size });
    update(page, size);
  };

  return (
    <>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        className={styles.AnalysisTable}
        size="small"
        scroll={{ x: 1400, y: height - 350 }}
        bordered
      />
      <div className={styles.paginationWrapper}>
        <div className={styles["total-info"]}>
          <ClockCircleOutlined />
          <Typography.Title level={5}>Total {total} h.</Typography.Title>
        </div>
        <Pagination
          showSizeChanger
          className={styles.pagination}
          current={pagination.page}
          onChange={onPageChange}
          total={analysis.totalElements}
          pageSize={pagination.size}
        />
      </div>
    </>
  );
};

export default AnalysisTable;
