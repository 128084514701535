import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Typography,
  Empty,
  Dropdown,
  Button,
  Menu,
  message,
  notification,
} from "antd";
import {
  DownOutlined,
  RightOutlined,
  LeftOutlined,
  SaveOutlined,
  CheckCircleOutlined,
  ReloadOutlined,
  SendOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { weekDays } from "@helpers/const";
import { ICurrentDate, ITask, ITaskDayActivities } from "./Current.types";
import Grid, { ITableData } from "@containers/MyTimesheets/Current/Grid/Grid";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { authSelector } from "@redux/slices/auth";
import _ from "lodash";
import { delegatedUserSelector } from "@redux/slices/delegatedUser";
import { getMyTasks } from "@redux/slices/myTasks";
import { myTasksSelector } from "@redux/slices/myTasks";
import {
  currentTimesheetSelector,
  getCurrentTimesheet,
  postCreateTimesheet,
  putSaveTimesheet,
  putRecallTimesheet,
  putSubmitTimesheet,
} from "@redux/slices/currentTimesheet";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import TimesheetApi from "@api/timesheet";
import TasksApi from "@api/tasks";
import styles from "./Current.module.scss";
import classNames from "classnames";
import Loader from "@components/Loader/Loader";
import Api from "@api/index";

const dateFormat = "DD-MM-YYYY";

const Current = () => {
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const params = new URLSearchParams(search);
  const { id } = useAppSelector(authSelector);
  const delegated = useAppSelector(delegatedUserSelector);
  const { actual } = useAppSelector(myTasksSelector);
  const { timesheet, loading } = useAppSelector(currentTimesheetSelector);
  const [tasks, setTasks] = useState<ITask[]>([]);
  const [isCreateBtnVisible, setIscreateBtnVisible] = useState(false);
  const [date, setDate] = useState<ICurrentDate>({
    dateFrom: search
      ? moment(params.get("dateFrom"))
      : moment().startOf("week"),
    dateTo: search ? moment(params.get("dateTo")) : moment().endOf("week"),
  });
  const [total, setTotal] = useState<ITableData>({
    key: "total",
    taskId: "total",
    taskName: "Total",
    resRemainWork: 0,
    mon: "0",
    tue: "0",
    wed: "0",
    thu: "0",
    fri: "0",
    sat: "0",
    sun: "0",
    total: 0,
  });
  const { userFullName } = useAppSelector(authSelector);

  // Данный useEffect нужен для того, чтобы подсветить юзеру незасабмиченные таймшиты.
  useEffect(() => {
    Api.timesheet
      .get({
        timesheetStatus: "DRAFT",
        enterpriseId: userFullName,
        page: 0,
        size: 100,
      })
      .then(
        (response) => {
          const unsubmittedWeeks = response.data.content.map(
            ({ timesheetDateStart, timesheetDateEnd }) =>
              `${timesheetDateStart} - ${timesheetDateEnd}`
          );

          if (unsubmittedWeeks.length === 1) {
            notification.warn({
              message: `Unsubmited timesheet detected: ${unsubmittedWeeks[0]}`,
            });
          }

          if (unsubmittedWeeks.length > 1) {
            notification.warn({
              message: `Unsubmited timesheets detected:`,
              description: (
                <ul>
                  {unsubmittedWeeks.map((week) => (
                    <li key={week}>{week}</li>
                  ))}
                </ul>
              ),
            });
          }
        },
        () => {}
      );
  }, [userFullName]);

  useEffect(() => {
    if (actual.length) {
      setTasks(
        timesheet.tasks.actual.map((el) => {
          const task = actual.find((elem) => elem.taskId === el.taskId);

          return {
            ...el,
            resRemainWork: task?.resRemainWork || 0,
            dayActivities: timesheet.dayActivities.reduce(
              (prev: ITaskDayActivities, curr) => {
                if (el.taskId === curr.taskId) {
                  return {
                    ...prev,
                    [weekDays[curr.activWeekday]]: curr,
                  };
                }
                return prev;
              },
              {}
            ),
          };
        })
      );
    } else {
      setTasks([]);
    }
  }, [timesheet, actual]);

  const saveTimesheet = async (
    tasks: ITask[],
    actionType: "save" | "submit"
  ) => {
    const dayActivities = tasks.reduce(
      (prev: TimesheetApi.IDayActivities[], curr) => {
        const activities = Object.values(curr.dayActivities).map(
          (el: TimesheetApi.IDayActivities) => {
            const value = {
              activResourceId: el.activResourceId,
              activActWork: +el.activActWork,
              activDate: el.activDate,
              activWeekday: el.activWeekday,
            };

            return el.id
              ? {
                  ...value,
                  id: el.id,
                }
              : value;
          }
        );
        return [...prev, ...activities];
      },
      []
    );

    if (actionType === "save") {
      await dispatch(
        putSaveTimesheet({
          timesheetDocVersion: 2,
          dayActivities,
          timesheetId: timesheet.id,
        })
      );
    } else {
      await dispatch(
        putSubmitTimesheet({
          timesheetDocVersion: 2,
          dayActivities,
          timesheetId: timesheet.id,
        })
      );
    }

    message.success(
      `Your timesheet was ${actionType === "save" ? "saved" : "submited"}.`,
      3
    );
  };

  useEffect(() => {
    const requestDate = _.cloneDeep(date);

    dispatch(
      getMyTasks({
        tsDateStart: requestDate.dateFrom.format("YYYY-MM-DD"),
        tsDateFinish: requestDate.dateTo.format("YYYY-MM-DD"),
      })
    );
  }, [date, dispatch]);

  useEffect(() => {
    if (!timesheet.id) {
      setIscreateBtnVisible(true);
    } else {
      setIscreateBtnVisible(false);
    }
  }, [timesheet]);

  useEffect(() => {
    const requestDate = _.cloneDeep(date);
    dispatch(
      getCurrentTimesheet({
        dateFrom: requestDate.dateFrom.format("YYYY-MM-DD"),
        dateTo: requestDate.dateTo.format("YYYY-MM-DD"),
        enterpriseId: delegated.id ? delegated.id : id,
      })
    );
  }, [dispatch, date, id, delegated]);

  const onDropDownItemClick = (value: TasksApi.IActualTask) => {
    setTasks((state) => [...state, { ...value, dayActivities: {} }]);
  };

  const onCreateTimesheet = useCallback(async () => {
    const requestDate = _.cloneDeep(date);
    await dispatch(
      postCreateTimesheet({
        timesheetStatus: "DRAFT",
        timesheetDateStart: date.dateFrom.format("YYYY-MM-DD"),
        timesheetDateEnd: date.dateTo.format("YYYY-MM-DD"),
        timesheetDocVersion: 0,
        timesheetEnterpriseId: id,
        dayActivities: [],
        previousTimesheet: null,
      })
    );
    dispatch(
      getCurrentTimesheet({
        dateFrom: requestDate.dateFrom.format("YYYY-MM-DD"),
        dateTo: requestDate.dateTo.format("YYYY-MM-DD"),
        enterpriseId: delegated.id ? delegated.id : id,
      })
    );
  }, [date, dispatch, id, delegated]);

  const myTasksMenuRender = useMemo(() => {
    const activeTasksId = tasks.map((el) => el.taskId);

    const items = actual.reduce((prev: ItemType[], curr) => {
      if (!activeTasksId.includes(curr.taskId)) {
        return [
          ...prev,
          {
            label: (
              <Button
                onClick={() => onDropDownItemClick(curr)}
                className={styles.button}
                type="link"
              >
                {curr.taskName}
              </Button>
            ),
            key: curr.taskName,
          },
        ];
      }
      return prev;
    }, []);

    const sortedItems = items.sort((a: any, b: any) =>
      a.key > b.key ? 1 : -1
    );

    return items.length
      ? sortedItems
      : [
          {
            label: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
            key: "Empty",
          },
        ];
  }, [actual, tasks]);

  const menu = <Menu items={myTasksMenuRender} className={styles.tasksMenu} />;

  const onSave = () => {
    saveTimesheet(tasks, "save");
  };

  const onSubmit = () => {
    saveTimesheet(tasks, "submit");
  };

  const onRecall = async () => {
    const requestDate = _.cloneDeep(date);

    await dispatch(putRecallTimesheet(timesheet.id));
    dispatch(
      getCurrentTimesheet({
        dateFrom: requestDate.dateFrom.format("YYYY-MM-DD"),
        dateTo: requestDate.dateTo.format("YYYY-MM-DD"),
        enterpriseId: delegated.id ? delegated.id : id,
      })
    );
  };

  const onPrevDate = () => {
    setDate({
      dateFrom: moment(date.dateFrom, dateFormat).subtract(7, "days"),
      dateTo: moment(date.dateTo, dateFormat).subtract(7, "days"),
    });
  };

  const onNextDate = () => {
    setDate({
      dateFrom: moment(date.dateFrom, dateFormat).add(7, "days"),
      dateTo: moment(date.dateTo, dateFormat).add(7, "days"),
    });
  };

  return (
    <>
      {loading && <Loader isAbsolute />}
      <div className={styles["current-top"]}>
        <div className={styles["current-title-block"]}>
          <span className={styles.title}>Timesheet</span>
          {timesheet.timesheetStatus && (
            <span
              className={
                timesheet.timesheetStatus === "SUBMITTED"
                  ? `${styles["current-submitted"]} ${styles["current-status"]}`
                  : styles["current-status"]
              }
            >
              {timesheet.timesheetStatus}
            </span>
          )}
        </div>
        <div className={styles["current-actions"]}>
          {isCreateBtnVisible ? (
            <Button
              style={{
                marginLeft: "1rem",
              }}
              size="large"
              className={styles.btn}
              type="primary"
              icon={<PlusOutlined />}
              onClick={onCreateTimesheet}
              disabled={loading || timesheet.timesheetStatus === "SUBMITTED"}
            >
              Create timesheet
            </Button>
          ) : (
            <>
              <Button
                className={styles.btn}
                danger
                size="large"
                onClick={onRecall}
                icon={<ReloadOutlined />}
                disabled={
                  loading ||
                  timesheet.timesheetStatus === "DRAFT" ||
                  isCreateBtnVisible
                }
              >
                Recall
              </Button>
              <Button
                className={styles.btn}
                onClick={onSave}
                size="large"
                icon={<SaveOutlined />}
                disabled={
                  loading ||
                  timesheet.timesheetStatus === "SUBMITTED" ||
                  isCreateBtnVisible
                }
              >
                Save
              </Button>
              <Button
                className={classNames({ [styles.btn]: !isCreateBtnVisible })}
                type="primary"
                onClick={onSubmit}
                size="large"
                icon={<SendOutlined />}
                disabled={
                  loading ||
                  timesheet.timesheetStatus === "SUBMITTED" ||
                  isCreateBtnVisible
                }
              >
                Submit
              </Button>
            </>
          )}
        </div>
      </div>
      <div className={styles["wrapper-date-block"]}>
        <div className={styles["date-block"]}>
          <div
            style={{
              display: "flex",
            }}
          >
            <Button
              type="text"
              onClick={onPrevDate}
              icon={<LeftOutlined />}
              disabled={loading}
            />
            <div className={styles["date-info"]}>
              <Typography.Title level={3}>
                {date.dateFrom.locale("en-gb").format("LL")}
              </Typography.Title>
              <span>—</span>
              <Typography.Title level={3}>
                {date.dateTo.locale("en-gb").format("LL")}
              </Typography.Title>
            </div>
            <Button
              type="text"
              onClick={onNextDate}
              icon={<RightOutlined />}
              disabled={loading}
            />
          </div>
          <Dropdown
            className={styles.btn}
            overlayClassName={styles.taskOverlay}
            overlay={menu}
            trigger={["click"]}
            disabled={
              loading ||
              timesheet.timesheetStatus === "SUBMITTED" ||
              isCreateBtnVisible
            }
          >
            <Button type="text" className={styles["add-task-btn"]}>
              <CheckCircleOutlined width={20} height={20} />
              Add Task
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>

        <div className={styles["total-info"]}>
          <Typography.Text type="secondary">Total</Typography.Text>
          <span className={styles["total-hours"]}>{total.total || 0}h</span>
        </div>
      </div>

      <Grid
        enterpriseId={id}
        timesheetId={timesheet.id}
        tasks={tasks}
        setTasks={setTasks}
        date={date}
        total={total}
        setTotal={setTotal}
      />
    </>
  );
};

export default Current;
