import { exceptionErrorCodes, jwtErrorCodes } from "@helpers/const";
import Helpers from "@helpers/utils";
import axios, { AxiosRequestConfig } from "axios";

namespace Http {
  export const Private = axios.create();

  export const Public = axios.create();

  const requestHandler = (request: AxiosRequestConfig) => request;

  const errorHandler = (error: any) => Promise.reject(error);

  Private.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
  );

  Public.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
  );

  Public.interceptors.response.use(
    (response) => response,
    (error) => {
      const {
        response: { data },
      } = error;

      if (!exceptionErrorCodes.includes(data.errors[0].code)) {
        Helpers.openNotificationWithIcon(
          "error",
          data.errors[0].message,
          data.errors[0].errorType
        );
      }

      return Promise.reject(error);
    }
  );

  Private.interceptors.response.use(
    (response) => response,
    (error) => {
      const {
        response: { data },
      } = error;

      const isExceptionError = data?.errors?.length
        ? exceptionErrorCodes.includes(data?.errors[0]?.code)
        : false;

      const isJWTError = data?.errors?.length
        ? jwtErrorCodes.includes(data?.errors[0]?.code)
        : false;

      if (!isExceptionError && data?.errors?.length) {
        Helpers.openNotificationWithIcon(
          "error",
          data.errors[0].message,
          data.errors[0].errorType
        );
      }

      if (isJWTError || error.response.status === 401) {
        localStorage.clear();

        return Promise.reject({ ...error, message: "Expired" });
      }

      return Promise.reject(error);
    }
  );
}

export default Http;
