import { IPageableRequest } from "@api/api";
import Loader from "@components/Loader/Loader";
import RejectAnalysisModal from "@components/RejectAnalysisModal/RejectAnalysisModal";
import { ICurrentDate } from "@containers/MyTimesheets/Current/Current.types";
import { pageTitles } from "@helpers/const";
import Hooks from "@helpers/hooks";
import Helpers from "@helpers/utils";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  analysisSelector,
  approveAnalysis,
  getAnalysis,
} from "@redux/slices/analysis";
import { authSelector } from "@redux/slices/auth";
import { Button, PageHeader } from "antd";
import _ from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AnalysisSearch from "../AnalysisSearch/AnalysisSearch";
import AnalysisTable, { ITableData } from "../AnalysisTable/AnalysisTable";
import styles from "../AnalysisTool.module.scss";
import { IAnalysisData, IDrawerApprove, IFilter } from "../AnalysisTool.types";
import { promiseAny } from "@helpers/promiseAny";

export const defaultCreateNewAnalysisState = {
  isVisible: false,
};

const adminColumns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    width: 100,
    sorter: (a: ITableData, b: ITableData) => Helpers.compare(a.date, b.date),
  },
  {
    title: "Executor",
    dataIndex: "analysisExecutor",
    key: "analysisExecutor",
    width: 100,
    sorter: (a: ITableData, b: ITableData) =>
      Helpers.compare(a.analysisExecutor, b.analysisExecutor),
  },
  {
    title: "Project",
    dataIndex: "projectName",
    key: "project",
    width: 100,
    sorter: (a: ITableData, b: ITableData) =>
      Helpers.compare(a.projectName, b.projectName),
  },
  {
    title: "Task",
    dataIndex: "taskName",
    key: "task",
    width: 100,
    sorter: (a: ITableData, b: ITableData) =>
      Helpers.compare(a.taskName, b.taskName),
  },
  {
    title: "Action type",
    dataIndex: "actionTypeName",
    key: "actionTypeName",
    width: 200,
    sorter: (a: ITableData, b: ITableData) =>
      Helpers.compare(a.actionTypeName, b.actionTypeName),
  },
  {
    title: "Effort",
    dataIndex: "effort",
    key: "effort",
    width: 60,
    sorter: (a: ITableData, b: ITableData) =>
      Helpers.compare(a.effort, b.effort),
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: 200,
    sorter: (a: ITableData, b: ITableData) =>
      Helpers.compare(a.description, b.description),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: 100,
    sorter: (a: ITableData, b: ITableData) =>
      Helpers.compare(a.status, b.status),
  },
  {
    title: "Reject comment",
    dataIndex: "rejectComment",
    key: "rejectComment",
    width: 200,
    sorter: (a: ITableData, b: ITableData) =>
      Helpers.compare(a.rejectComment, b.rejectComment),
  },
];

const AnalysisApprove = () => {
  const Portal = Hooks.usePortal(document.getElementById("pageHeader"));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [selectedRows, setSelectedRows] = useState<IAnalysisData>([]);
  const { id } = useAppSelector(authSelector);
  const { loading, analysis } = useAppSelector(analysisSelector);
  const { search } = useLocation();
  const [pagination, setPagination] = useState<IPageableRequest>({
    page: 1,
    size: 50,
  });
  const params = new URLSearchParams(search);
  const [date, setDate] = useState<ICurrentDate>({
    dateFrom: search
      ? moment(params.get("dateFrom"))
      : moment().startOf("week"),
    dateTo: search ? moment(params.get("dateTo")) : moment().endOf("week"),
  });
  const [filters, setFilters] = useState<IFilter>({});
  const defaultFilters = useMemo(
    () => ({
      status: ["WAITING_APPROVE"],
      analysisApprover: id,
    }),
    [id]
  );
  const requestDate = _.cloneDeep(date);
  const defaultRejectDrawerState = {
    isVisible: false,
  };
  const [rejectDrawerState, setRejectDrawerState] = useState<IDrawerApprove>(
    defaultRejectDrawerState
  );

  const update = useCallback(
    (page: number, size: number) => {
      const requestDate = _.cloneDeep(date);
      dispatch(
        getAnalysis({
          startDate: requestDate.dateFrom.format("YYYY-MM-DD"),
          endDate: requestDate.dateTo.format("YYYY-MM-DD"),
          page: page - 1,
          size,
          ...defaultFilters,
          ...filters,
        })
      );
    },
    [date, defaultFilters, dispatch, filters]
  );

  useEffect(() => {
    dispatch(
      getAnalysis({
        startDate: requestDate.dateFrom.format("YYYY-MM-DD"),
        endDate: requestDate.dateTo.format("YYYY-MM-DD"),
        page: 0,
        size: 50,
        ...defaultFilters,
        ...filters,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, date]);

  const onApprove = useCallback(async () => {
    const tasksIdsByExecutor: Record<string, string[]> = {};

    selectedRows.forEach(({ analysisRecordId, analysisExecutor }) => {
      tasksIdsByExecutor[analysisExecutor] = tasksIdsByExecutor[
        analysisExecutor
      ]
        ? [...tasksIdsByExecutor[analysisExecutor], analysisRecordId]
        : [analysisRecordId];
    });

    const requestDate = _.cloneDeep(date);

    await promiseAny(
      Object.values(tasksIdsByExecutor).map((tasksIds) =>
        dispatch(
          approveAnalysis({
            dates: {
              dateStart: requestDate.dateFrom.format("YYYY-MM-DD"),
              dateFinish: requestDate.dateTo.format("YYYY-MM-DD"),
            },
            content: tasksIds,
          })
        )
      )
    );

    update(pagination.page, pagination.size);
    setSelectedRows([]);
  }, [dispatch, pagination, date, selectedRows, update]);

  const onReject = () => {
    setRejectDrawerState({ isVisible: true });
  };

  console.log("selectedRows", selectedRows);

  return (
    <>
      {loading && <Loader isAbsolute />}
      <Portal>
        <PageHeader
          className={styles.header}
          onBack={() => navigate(-1)}
          title={pageTitles[pathname as keyof typeof pageTitles]}
        />
      </Portal>
      <AnalysisSearch
        isApprove
        pagination={pagination}
        analysis={analysis}
        date={date}
        defaultFilters={defaultFilters}
        filters={filters}
        setFilters={setFilters}
        setDate={setDate}
      />

      <div className={styles.table}>
        <AnalysisTable
          pagination={pagination}
          setPagination={setPagination}
          update={update}
          columns={adminColumns}
          analysis={analysis}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
        ></AnalysisTable>
      </div>

      <div className={styles.analysisBtns}>
        <Button
          type="primary"
          key="create"
          disabled={selectedRows.length ? false : true}
          onClick={onApprove}
        >
          Approve several
        </Button>
        <Button
          key="send"
          onClick={onReject}
          disabled={selectedRows.length ? false : true}
        >
          Reject several
        </Button>
      </div>
      <RejectAnalysisModal
        setSelectedRows={setSelectedRows}
        rejectDrawerState={rejectDrawerState}
        setRejectDrawerState={setRejectDrawerState}
        selectedRows={selectedRows}
        defaultFilters={defaultFilters}
        startDate={requestDate.dateFrom.format("YYYY-MM-DD")}
        endDate={requestDate.dateTo.format("YYYY-MM-DD")}
      ></RejectAnalysisModal>
    </>
  );
};

export default AnalysisApprove;
