import { RootState } from "@redux/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "@api/index";
import analysisApi from "@api/analysis";
import { defaultPageableContent } from "@api/const";

const initialState: IInitial = {
  analysis: defaultPageableContent,
  loading: false,
};

interface IInitial {
  analysis: analysisApi.IGetResponse;
  loading: boolean;
}

export const getAnalysis = createAsyncThunk(
  "analysis/getAnalysis",
  async (payload: analysisApi.IGetRequest) => {
    const { data } = await Api.analysis.get(payload);

    return data;
  }
);

export const postAnalysis = createAsyncThunk(
  "analysis/postAnalysis",
  async (payload: analysisApi.IPostRequest) => {
    const { data } = await Api.analysis.post(payload);

    return data;
  }
);

export const deleteAnalysis = createAsyncThunk(
  "analysis/deleteAnalysis",
  async (payload: analysisApi.IDeleteItemsRequest) => {
    const { data } = await Api.analysis.deleteItems(payload);

    return data;
  }
);

export const patchAnalysis = createAsyncThunk(
  "analysis/patchAnalysis",
  async (payload: analysisApi.IPatchToApprove) => {
    const { data } = await Api.analysis.patchToApprove(payload);

    return data;
  }
);

export const updateAnalysis = createAsyncThunk(
  "analysis/updateAnalysis",
  async (payload: analysisApi.IPostData[]) => {
    await Api.analysis.putUpdate(payload);
  }
);

export const approveAnalysis = createAsyncThunk(
  "analysis/approveAnalysis",
  async (payload: analysisApi.IPatchRequest) => {
    const { data } = await Api.analysis.approve(payload);

    return data;
  }
);

export const rejectAnalysis = createAsyncThunk(
  "analysis/rejectAnalysis",
  async (payload: analysisApi.IRejectData) => {
    const { data } = await Api.analysis.patchReject(payload);

    return data;
  }
);

export const postCreateReport = createAsyncThunk(
  "analysis/postCreateAtReport",
  async (payload: analysisApi.ICreateReportRequest) => {
    const { data } = await Api.analysis.createReport(payload);

    return data;
  }
);

export const analysisSlice = createSlice({
  name: "analysis",
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAnalysis.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        getAnalysis.fulfilled,
        (state, action: PayloadAction<analysisApi.IGetResponse | null>) => {
          if (action.payload) {
            return {
              analysis: action.payload,
              loading: false,
            };
          }
          return state;
        }
      )
      .addCase(postCreateReport.fulfilled, (_, action: PayloadAction<Blob>) => {
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(action.payload);
        a.href = url;
        a.download = "analysisToolReport.xlsx";
        a.click();
      });
  },
});

export const { clear } = analysisSlice.actions;

export const analysisSelector = (state: RootState) => state.analysis;

export default analysisSlice.reducer;
