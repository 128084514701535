import { AxiosResponse } from "axios";
import { IPageableRequest, IPageableResponse } from "./api";
import { projectsUrl } from "./const";
import Http from "./https";

namespace ProjectsApi {
  export interface IResponseContent {
    id: string;
    projTitle: string;
    projManager: string;
    projDetails: string;
  }

  export interface IGetResponse extends IPageableResponse {
    content: IResponseContent[];
  }

  export const get = async (
    data?: IPageableRequest
  ): Promise<AxiosResponse<IGetResponse>> => {
    return await Http.Private.get(projectsUrl, {
      params: data,
    });
  };
}

export default ProjectsApi;
