import { useState, useEffect, useRef, useCallback } from "react";
import ReactDOM from "react-dom";

namespace Hooks {
  export const useDebounce = <T>(value: T, delay: number): T => {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  };

  export const usePrevious = <T>(value: T) => {
    const ref = useRef<T>();

    useEffect(() => {
      ref.current = value;
    }, [value]);

    return ref.current;
  };

  export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
      width: 0,
      height: 0,
    });

    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    useEffect(() => {
      window.addEventListener("resize", handleResize);

      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
  };

  interface IPortal {
    render: ({
      children,
    }: {
      children: React.ReactNode;
    }) => null | React.ReactPortal;
    remove: (el: HTMLElement | null) => null | boolean;
  }

  export const usePortal = (el: HTMLElement | null) => {
    const [portal, setPortal] = useState<IPortal>({
      render: () => null,
      remove: () => null,
    });

    const createPortal = useCallback((el: HTMLElement | null): IPortal => {
      if (el) {
        const Portal = ({ children }: { children: React.ReactNode }) =>
          ReactDOM.createPortal(children, el);
        const removePortal = () => ReactDOM.unmountComponentAtNode(el);
        return { render: Portal, remove: removePortal };
      }
      return {
        render: () => null,
        remove: () => null,
      };
    }, []);

    useEffect(() => {
      if (el) {
        portal.remove(el);
      }
      const newPortal = createPortal(el);
      setPortal(newPortal);
      return () => {
        newPortal.remove(el);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createPortal, el]);

    return portal.render;
  };
}

export default Hooks;
