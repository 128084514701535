import { RootState } from "@redux/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import TimesheetApi from "@api/timesheet";
import Api from "@api/index";

interface IInitialState {
  timesheet: TimesheetApi.IGetResponseContent;
  loading: boolean;
}

const initialState: IInitialState = {
  timesheet: {
    id: "",
    timesheetStatus: "",
    timesheetDateStart: "",
    timesheetDateEnd: "",
    timesheetDocVersion: 0,
    timesheetEnterpriseId: "",
    dayActivities: [],
    tasks: {
      actual: [],
      outdated: [],
    },
  },
  loading: false,
};

export const getCurrentTimesheet = createAsyncThunk(
  "currentTimesheets/getCurrentTimesheet",
  async (payload?: TimesheetApi.IGetCurrentRequest) => {
    const { data } = await Api.timesheet.getCurrent(payload);

    return data;
  }
);

export const putSaveTimesheet = createAsyncThunk(
  "currentTimesheets/putSaveTimesheet",
  async (payload: TimesheetApi.ISaveRequest) => {
    const { data } = await Api.timesheet.save(payload);

    return data;
  }
);

export const putSubmitTimesheet = createAsyncThunk(
  "currentTimesheets/putSubmitTimesheet",
  async (payload: TimesheetApi.ISaveRequest) => {
    const { data } = await Api.timesheet.submit(payload);

    return data;
  }
);

export const putRecallTimesheet = createAsyncThunk(
  "currentTimesheets/putRecallTimesheet",
  async (payload: string) => {
    const { data } = await Api.timesheet.recall(payload);

    return data;
  }
);

export const postCreateTimesheet = createAsyncThunk(
  "currentTimesheets/postCreateTimesheet",
  async (payload: TimesheetApi.IPostRequest) => {
    const data = await Api.timesheet.post(payload);

    return data;
  }
);

export const currentTimesheetSlice = createSlice({
  name: "currentTimesheets",
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentTimesheet.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        getCurrentTimesheet.fulfilled,
        (_, action: PayloadAction<TimesheetApi.IGetResponseContent>) => ({
          timesheet: action.payload,
          loading: false,
        })
      )
      .addCase(getCurrentTimesheet.rejected, () => ({
        ...initialState,
        loading: false,
      }))
      .addCase(putSaveTimesheet.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        putSaveTimesheet.fulfilled,
        (_, action: PayloadAction<TimesheetApi.IGetResponseContent>) => ({
          timesheet: action.payload,
          loading: false,
        })
      )
      .addCase(putSubmitTimesheet.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        putSubmitTimesheet.fulfilled,
        (_, action: PayloadAction<TimesheetApi.IGetResponseContent>) => ({
          timesheet: action.payload,
          loading: false,
        })
      );
  },
});

export const { clear } = currentTimesheetSlice.actions;

export const currentTimesheetSelector = (state: RootState) =>
  state.currentTimesheet;

export default currentTimesheetSlice.reducer;
