import { DeleteOutlined } from "@ant-design/icons";
import {
  IAnalysisData,
  IAnalysisRecord,
  IDrawerApprove,
} from "@containers/AnalysisTool/AnalysisTool.types";
import Hooks from "@helpers/hooks";
import { getAnalysis, rejectAnalysis } from "@redux/slices/analysis";
import { useAppDispatch } from "@redux/hooks";
import { Button, Drawer, Form, Input, Popconfirm, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { FC, useEffect, useState } from "react";
import styles from "./RejectAnalysisModal.module.scss";
import { promiseAny } from "@helpers/promiseAny";

interface IRejectAnalysis {
  selectedRows: IAnalysisData;
  setSelectedRows: React.Dispatch<React.SetStateAction<IAnalysisData>>;
  rejectDrawerState: IDrawerApprove;
  setRejectDrawerState: React.Dispatch<React.SetStateAction<IDrawerApprove>>;
  defaultFilters: any;
  startDate: string;
  endDate: string;
}

const RejectAnalysisModal: FC<IRejectAnalysis> = ({
  selectedRows,
  rejectDrawerState,
  setRejectDrawerState,
  setSelectedRows,
  defaultFilters,
  startDate,
  endDate,
}) => {
  const { height, width } = Hooks.useWindowSize();
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState<IAnalysisData>(selectedRows);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setTableData(selectedRows);
  }, [selectedRows]);

  useEffect(() => {
    tableData.forEach((el) => {
      form.setFieldsValue({
        [`comment-${el.analysisRecordId}`]: el.rejectComment,
      });
    });
  }, [form, tableData]);

  const columns: ColumnsType<IAnalysisRecord> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "analysisDate",
      width: 120,
    },
    {
      title: "Executor",
      dataIndex: "analysisExecutor",
      key: "analysisExecutor",
      width: 120,
    },
    {
      title: "Project",
      dataIndex: "projectName",
      key: "project",
      width: 150,
    },
    {
      title: "Task",
      dataIndex: "taskName",
      key: "task",
      width: 150,
    },
    {
      title: "Action type",
      dataIndex: "actionTypeName",
      key: "actionTypeName",
      width: 280,
    },
    {
      title: "Effort",
      dataIndex: "effort",
      key: "effort",
      width: 70,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 250,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
    },
    {
      title: "Reject comment",
      dataIndex: "rejectComment",
      key: "rejectComment",
      width: 200,
      render: (text, record, index) => {
        return (
          <Form.Item
            name={`comment-${record.analysisRecordId}`}
            className={styles.formItem}
            rules={[{ required: true, message: "Required" }]}
          >
            <Input
              placeholder="reject comment"
              onChange={(e) =>
                onAnalysisChange("rejectComment", index, e.target.value)
              }
              className={styles.newAnalysisItem}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "",
      dataIndex: "operation",
      width: 30,
      render: (_, record) =>
        tableData.length > 1 ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.analysisRecordId)}
          >
            <DeleteOutlined />
          </Popconfirm>
        ) : null,
    },
  ];

  const onClose = () => {
    setRejectDrawerState({ isVisible: false });
    form.resetFields();
    setSelectedRows([]);
  };

  const onAnalysisChange = (
    dataIndex: string,
    index: number,
    value: String
  ) => {
    const newData = tableData.map((item, itemIndex) =>
      index === itemIndex
        ? {
            ...item,
            [dataIndex]: value,
          }
        : item
    );
    setTableData(newData);
  };

  const onSave = async () => {
    await form.validateFields();
    form.submit();

    const rejectDataByExecutor: Record<
      string,
      {
        id: string;
        rejectComment: string;
      }[]
    > = {};

    tableData.forEach(
      ({ analysisRecordId, rejectComment, analysisExecutor }) => {
        const item = {
          id: analysisRecordId,
          rejectComment,
        };

        rejectDataByExecutor[analysisExecutor] = rejectDataByExecutor[
          analysisExecutor
        ]
          ? [...rejectDataByExecutor[analysisExecutor], item]
          : [item];
      }
    );

    await promiseAny(
      Object.values(rejectDataByExecutor).map((rejectData) =>
        dispatch(
          rejectAnalysis({
            data: rejectData,
            dates: {
              dateStart: startDate,
              dateFinish: endDate,
            },
          })
        )
      )
    );

    onClose();

    dispatch(
      getAnalysis({
        startDate: startDate,
        endDate: endDate,
        page: 0,
        size: 100,
        ...defaultFilters,
      })
    );
  };

  const handleDelete = (analysisRecordId: any) => {
    const names = form.getFieldsValue(); // получаем все имена итемов формы
    const clearFields = Object.keys(names).filter((item) =>
      item.includes(`${analysisRecordId}`)
    ); // ищем имена тех итемов которые надо очистить
    const newData = tableData.filter(
      (item) => item.analysisRecordId !== analysisRecordId
    ); // ищем данные которые надо удалить из tableData
    form.resetFields(clearFields); // очищаем нужные поля формы
    setTableData(newData); //  обновляем tableData
  };

  return (
    <Drawer
      visible={rejectDrawerState.isVisible}
      onClose={onClose}
      destroyOnClose={true}
      title="Reject analysis"
      width={width - 100}
    >
      <div className={styles.rejectAnalysisWrapper}>
        <Form form={form} name="rejectAnalysis">
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            size="small"
            scroll={{ y: height - 200 }}
            bordered
          ></Table>
        </Form>
        <div className={styles.rejectActions}>
          <Button type="primary" onClick={onSave}>
            Reject
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </div>
      </div>
    </Drawer>
  );
};

export default RejectAnalysisModal;
