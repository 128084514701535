import { RootState } from "@redux/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "@api/index";
import TasksApi from "@api/tasks";

interface IInitialState {
  task: TasksApi.IGeResponseContent;
  loading: boolean;
}

const initialState: IInitialState = {
  task: {
    id: "",
    taskRemainWork: 0,
    taskName: "",
    taskType: "",
    taskParentTaskId: null,
    taskDetails: "",
    taskBaseline: 0,
    taskActWork: null,
    taskPredTaskId: null,
    taskSuccTaskId: null,
    taskBaseStartDate: "",
    taskBaseFinishDate: "",
    taskActStartDate: null,
    taskActFinishDate: null,
    project: undefined,
    taskChargeCode: "",
    subTasks: null,
    resources: null,
  },
  loading: false,
};

export const getOneTask = createAsyncThunk(
  "currentTask/getOneTask",
  async (payload: string) => {
    const { data } = await Api.tasks.getOne(payload);

    return data;
  }
);

export const currentTaskSlice = createSlice({
  name: "currentTask",
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOneTask.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        getOneTask.fulfilled,
        (state, action: PayloadAction<TasksApi.IGeResponseContent | null>) => {
          if (action.payload) {
            return {
              task: action.payload,
              loading: false,
            };
          }
          return state;
        }
      );
  },
});

export const { clear } = currentTaskSlice.actions;

export const currentTaskSelector = (state: RootState) => state.currentTask;

export default currentTaskSlice.reducer;
