import { useEffect, useMemo, useState, useCallback } from "react";
import { PageHeader, Button } from "antd";
import { pageTitles } from "@helpers/const";
import Hooks from "@helpers/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import styles from "./ExternalTool.module.scss";
import ExternalTable, { ITableData } from "./ExternalTable/ExternalTable";
import CreateNewExternal from "@components/CreateNewExternal/CreateNewExternal";
import {
  IExternalData,
  ICurrentDate,
  IDrawerCreate,
} from "./ExternalTool.types";
import ExternalSearch from "./ExternalSearch/ExternalSearch";
import moment from "moment";
import Loader from "@components/Loader/Loader";
import {
  getExternal,
  externalSelector,
  deleteExternal,
} from "@redux/slices/external";
import _ from "lodash";
import { authSelector } from "@redux/slices/auth";
import { IPageableRequest } from "@api/api";
import Helpers from "@helpers/utils";
import externalApi from "@api/external";

export const defaultCreateNewExternalState = {
  isEdit: false,
  isVisible: false,
};

const ExternalTool = () => {
  const Portal = Hooks.usePortal(document.getElementById("pageHeader"));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [filters, setFilters] = useState({});
  const [selectedRows, setSelectedRows] = useState<IExternalData>([]);
  const [createNewExternalState, setCreateNewExternalState] =
    useState<IDrawerCreate>(defaultCreateNewExternalState);
  const [pagination, setPagination] = useState<IPageableRequest>({
    page: 1,
    size: 50,
  });
  const { loading } = useAppSelector(externalSelector);
  const { id } = useAppSelector(authSelector);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const defaultFilters = useMemo(
    () => ({ analysisRecordType: ["EXTERNAL"], analysisExecutor: id }),
    [id]
  );
  const [date, setDate] = useState<ICurrentDate>({
    dateFrom: search
      ? moment(params.get("dateFrom"))
      : moment().startOf("week"),
    dateTo: search ? moment(params.get("dateTo")) : moment().endOf("week"),
  });

  const { external } = useAppSelector(externalSelector);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 100,
      sorter: (a: ITableData, b: ITableData) => Helpers.compare(a.date, b.date),
    },
    {
      title: "Project",
      dataIndex: "projectName",
      key: "project",
      width: 100,
      sorter: (a: ITableData, b: ITableData) =>
        Helpers.compare(a.projectName, b.projectName),
    },
    {
      title: "Task",
      dataIndex: "taskName",
      key: "task",
      width: 100,
      sorter: (a: ITableData, b: ITableData) =>
        Helpers.compare(a.taskName, b.taskName),
    },
    {
      title: "Effort",
      dataIndex: "effort",
      key: "effort",
      width: 60,
      sorter: (a: ITableData, b: ITableData) =>
        Helpers.compare(a.effort, b.effort),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 200,
      sorter: (a: ITableData, b: ITableData) =>
        Helpers.compare(a.description, b.description),
    },
  ];

  const update = useCallback(
    (page: number, size: number) => {
      const requestDate = _.cloneDeep(date);
      dispatch(
        getExternal({
          startDate: requestDate.dateFrom.format("YYYY-MM-DD"),
          endDate: requestDate.dateTo.format("YYYY-MM-DD"),
          page: page - 1,
          size,
          ...filters,
          ...defaultFilters,
        })
      );
    },
    [date, defaultFilters, dispatch, filters]
  );

  useEffect(() => {
    if (id) {
      const requestDate = _.cloneDeep(date);
      dispatch(
        getExternal({
          startDate: requestDate.dateFrom.format("YYYY-MM-DD"),
          endDate: requestDate.dateTo.format("YYYY-MM-DD"),
          page: 0,
          size: 50,
          ...defaultFilters,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, date]);

  const onCreate = () => {
    setCreateNewExternalState({
      isVisible: true,
      isEdit: false,
    });
  };

  const onEdit = () => {
    setCreateNewExternalState({
      isVisible: true,
      isEdit: true,
    });
  };

  const onDelete = useCallback(async () => {
    const requestDate = _.cloneDeep(date);

    const deleteData: externalApi.IDelRequest = {
      content: selectedRows.map((el) => el.analysisRecordId),
      dates: {
        dateStart: requestDate.dateFrom.format("YYYY-MM-DD"),
        dateFinish: requestDate.dateTo.format("YYYY-MM-DD"),
      },
    };
    await dispatch(deleteExternal(deleteData));
    update(pagination.page, pagination.size);
  }, [dispatch, pagination, selectedRows, update, date]);

  return (
    <>
      {loading && <Loader isAbsolute />}
      <Portal>
        <PageHeader
          className={styles.header}
          onBack={() => navigate(-1)}
          title={pageTitles[pathname]}
        />
      </Portal>

      <ExternalSearch
        pagination={pagination}
        filters={filters}
        setFilters={setFilters}
        date={date}
        setDate={setDate}
        defaultFilters={defaultFilters}
        external={external}
      />
      <div className={styles.table}>
        <ExternalTable
          pagination={pagination}
          setPagination={setPagination}
          update={update}
          columns={columns}
          external={external}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
        ></ExternalTable>
      </div>
      <div className={styles.externalBtns}>
        <Button type="primary" key="create" onClick={onCreate}>
          Create new
        </Button>
        <Button
          type="default"
          danger
          disabled={!selectedRows.length}
          key="edit"
          onClick={onDelete}
        >
          Delete
        </Button>
        <Button
          type="default"
          disabled={!selectedRows.length}
          key="edit"
          onClick={onEdit}
        >
          Edit
        </Button>
      </div>
      <CreateNewExternal
        dates={date}
        filters={filters}
        createNewExternalState={createNewExternalState}
        setCreateNewExternalState={setCreateNewExternalState}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        defaultFilters={defaultFilters}
      />
    </>
  );
};

export default ExternalTool;
