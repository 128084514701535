import { Result, Space, Typography } from "antd";
import React from "react";
import styles from "./ErrorBoundary.module.scss";

type ErrorBoundaryProps = {
  children:
    | string
    | number
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
};

type ErrorBoundaryState = {
  hasError: boolean;
  errorMesage: string[];
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, errorMesage: [] };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    const errorMessage = error.stack?.split("at ");
    this.setState({
      errorMesage: errorMessage || [],
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Result
          className={styles.result}
          status="error"
          title="Error"
          subTitle="Sorry, something went wrong we are trying to fix it."
        >
          <Space direction="vertical">
            {this.state.errorMesage.map((el, index) =>
              index === 0 ? (
                <Typography.Title level={5}>{el}</Typography.Title>
              ) : (
                <Typography.Text>{`at ${el}`}</Typography.Text>
              )
            )}
          </Space>
        </Result>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
