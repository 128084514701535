import { AxiosResponse } from "axios";
import { authUrl, currentUserUrl } from "./const";
import Http from "./https";

namespace AuthApi {
  export interface IRequest {
    email: string;
    password: string;
  }

  export interface IResponse {
    accessToken: string;
    tokenType: string;
  }

  export interface IMeResponse {
    id: string;
    userFullName: string;
    userProjectRole: string;
    userEmail: string;
    userAccessLevel: string;
    userLoad: string;
  }

  export const signIn = async (
    data: IRequest
  ): Promise<AxiosResponse<IResponse>> => {
    return await Http.Public.post(authUrl, data);
  };

  export const getMe = async (): Promise<AxiosResponse<IMeResponse>> => {
    return await Http.Private.get(currentUserUrl);
  };
}

export default AuthApi;
