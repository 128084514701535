import React, { FC } from "react";
import { DatePicker, Modal, Button, Form } from "antd";
import { useAppDispatch } from "@redux/hooks";
import { postCreateReport } from "@redux/slices/analysis";

interface IDownloadAtReport {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const DownloadAtReport: FC<IDownloadAtReport> = ({
  isVisible,
  setIsVisible,
}) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const handleOk = async () => {
    await form.validateFields();
    const {
      date: [dateFrom, dateTo],
    } = form.getFieldsValue();

    dispatch(
      postCreateReport({
        dateFrom,
        dateTo,
      })
    );

    setIsVisible(false);
    form.resetFields();
  };

  const handleCancel = () => {
    setIsVisible(false);
    form.resetFields();
  };

  return (
    <Modal
      visible={isVisible}
      title="Download Analysis Report"
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Close
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          Export to excel
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="atReport"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        autoComplete="off"
      >
        <Form.Item
          name="date"
          rules={[{ required: true, message: "Please select date interval" }]}
        >
          <DatePicker.RangePicker style={{ width: "100%" }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DownloadAtReport;
