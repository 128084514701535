import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Layout from "@components/Layout/Layout";
import Auth from "@containers/Auth/Auth";
import {
  ANALYSIS_APPROVE_PAGE,
  ANALYSIS_TOOL_PAGE,
  AUTH_PAGE,
  CURRENT_TIMESHEETS_PAGE,
  MY_TIMESHEETS_PAGE,
  TASK_CREATION_PAGE,
  EXTERNAL_TOOL_PAGE,
} from "@helpers/routes";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { authSelector, me, setIsExpired, setToken } from "@redux/slices/auth";
import MyTimesheets from "@containers/MyTimesheets/MyTimesheets";
import TaskCreation from "@containers/TaskCreation/TaskCreation";
import ErrorBoundary from "@components/ErrorBoundary/ErrorBoundary";
import Http from "@api/https";
import moment from "moment";
import "moment/locale/ru";
import AnalysisTool from "@containers/AnalysisTool/AnalysisTool";
import ExternalTool from "@containers/ExternalTool/ExternalTool";
import AnalysisApprove from "@containers/AnalysisTool/AnalysisApprove/AnalysisApprove";

const App = () => {
  moment.locale("ru");
  const { accessToken, isAuth, isExpired } = useAppSelector(authSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const storageToken = localStorage.getItem("accessToken");

    if (storageToken) {
      dispatch(setToken(storageToken));
    } else {
      dispatch(setIsExpired(true));
    }
  }, [dispatch]);

  useEffect(() => {
    if (accessToken) {
      Http.Private.defaults.headers.common.Authorization = accessToken;
      dispatch(me());
    }
  }, [dispatch, accessToken]);

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          {isExpired && (
            <Route path="*" element={<Navigate to={AUTH_PAGE} replace />} />
          )}
          {isAuth ? (
            <>
              <Route
                path={`${MY_TIMESHEETS_PAGE}/*`}
                element={<MyTimesheets />}
              />
              <Route
                path={`${TASK_CREATION_PAGE}`}
                element={
                  <ErrorBoundary>
                    <TaskCreation />
                  </ErrorBoundary>
                }
              />
              <Route
                path={`${ANALYSIS_TOOL_PAGE}`}
                element={
                  <ErrorBoundary>
                    <AnalysisTool />
                  </ErrorBoundary>
                }
              />
              <Route
                path={`${ANALYSIS_APPROVE_PAGE}`}
                element={
                  <ErrorBoundary>
                    <AnalysisApprove />
                  </ErrorBoundary>
                }
              />
              <Route
                path={`${EXTERNAL_TOOL_PAGE}`}
                element={
                  <ErrorBoundary>
                    <ExternalTool />
                  </ErrorBoundary>
                }
              />
              <Route
                path="/"
                element={<Navigate to={CURRENT_TIMESHEETS_PAGE} replace />}
              />
            </>
          ) : (
            <>
              <Route path={AUTH_PAGE} element={<Auth />} />
            </>
          )}
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export default App;
