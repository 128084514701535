import React, { FC, useEffect, useState } from "react";
import { Select, Modal, Button, Form, Input } from "antd";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  getUsers,
  postUsers,
  putUsers,
  usersSelector,
} from "@redux/slices/users";
import UsersApi from "@api/users";
import Helpers from "@helpers/utils";
import { getOneUser, selectedUserSelector } from "@redux/slices/selectedUser";

interface ICreateResourceModalState {
  isVisible: boolean;
  resourceId?: string;
  taskId?: string;
  isEdit: boolean;
}

interface ICreateResource {
  createResourceModal: ICreateResourceModalState;
  setCreateResourceModal: React.Dispatch<
    React.SetStateAction<ICreateResourceModalState>
  >;
}

const CreateResource: FC<ICreateResource> = ({
  createResourceModal,
  setCreateResourceModal,
}) => {
  const [form] = Form.useForm<UsersApi.IPostRequest>();
  const [enterpiseId, setEnterpriseId] = useState("");
  const dispatch = useAppDispatch();
  const { content } = useAppSelector(usersSelector);
  const selected = useAppSelector(selectedUserSelector);
  const [data, setData] = useState(content);

  useEffect(() => {
    if (createResourceModal.isVisible) {
      dispatch(getUsers({ size: 1000, page: 0 }));
      if (createResourceModal.resourceId) {
        onEnterpriseIdChange(createResourceModal.resourceId);
      }
    }
  }, [dispatch, createResourceModal]);

  useEffect(() => {
    setData(content);
  }, [content]);

  useEffect(() => {
    if (createResourceModal.isVisible) {
      dispatch(getOneUser(enterpiseId));
    }
  }, [enterpiseId, dispatch, createResourceModal]);

  useEffect(() => {
    form.setFieldsValue({
      id: selected.id,
      userEmail: selected.userEmail,
      userAccessLevel: selected.userAccessLevel || undefined,
      userFullName: selected.userFullName,
      userLoad: selected.userLoad,
      userProjectRole: selected.userProjectRole,
    });
  }, [selected, form]);

  const handleOk = async () => {
    const data = form.getFieldsValue();
    await form.validateFields();
    if (createResourceModal.isEdit) {
      dispatch(putUsers(data));
    } else {
      dispatch(postUsers(data));
    }
    setCreateResourceModal({
      isVisible: false,
      isEdit: false,
    });
    Helpers.openNotificationWithIcon("success", "User was created", "CUSTOM");
    form.resetFields();
  };

  const handleCancel = () => {
    setCreateResourceModal({
      isVisible: false,
      isEdit: false,
    });
    form.resetFields();
  };

  const onSearch = (value: string) => {
    setData(content.filter((el) => el.id.includes(value)));
  };

  const onEnterpriseIdChange = (value: string) => {
    setEnterpriseId(value);
  };

  return (
    <Modal
      visible={createResourceModal.isVisible}
      title={`${createResourceModal.isEdit ? "Edit" : "Add"} resource`}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Close
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk}>
          {`${createResourceModal.isEdit ? "Edit" : "Add"} resource`}
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="createResource"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        autoComplete="off"
      >
        <Form.Item
          name="id"
          rules={[
            {
              required: true,
              message: `Please ${
                createResourceModal.isEdit ? "select" : "input"
              } user enterprise ID`,
            },
          ]}
        >
          {createResourceModal.isEdit ? (
            <Select
              showSearch
              placeholder="Resource Enterprise ID"
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={onSearch}
              onChange={onEnterpriseIdChange}
              notFoundContent={null}
            >
              {data.map((el) => (
                <Select.Option key={el.id} value={el.id}>
                  {el.id}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Input placeholder="Resource Enterprise ID" />
          )}
        </Form.Item>
        <Form.Item
          name="userEmail"
          rules={[{ required: true, message: "Please input user email" }]}
        >
          <Input placeholder="Resource Email" />
        </Form.Item>
        <Form.Item
          name="userPassword"
          rules={[{ required: true, message: "Please input user password" }]}
        >
          <Input.Password placeholder="Resource Password" />
        </Form.Item>
        <Form.Item
          name="userAccessLevel"
          rules={[{ required: true, message: "Please select access level" }]}
        >
          <Select placeholder="Resource Role">
            <Select.Option value="USER">User</Select.Option>
            <Select.Option value="ADMIN">Administrator</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="userFullName"
          rules={[{ required: true, message: "Please input user full name" }]}
        >
          <Input placeholder="Resource Name" />
        </Form.Item>
        <Form.Item
          name="userLoad"
          rules={[{ required: true, message: "Please input user load" }]}
        >
          <Input placeholder="Resource load(hours)" />
        </Form.Item>
        <Form.Item
          name="userProjectRole"
          rules={[
            { required: true, message: "Please input user project role" },
          ]}
        >
          <Input placeholder="Resource Project Role" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateResource;
