export const usersUrl = "/v1/users";

export const currentUserUrl = `${usersUrl}/current`;

export const timesheetUrl = "/v1/timesheet";

export const currentTimesheetUrl = `${timesheetUrl}/unique-index`;

export const createReportUrl = `/v1/timesheet/create-report`;

export const projectsUrl = "/v1/projects";

export const tasksUrl = "/v1/tasks";

export const myTasksUrl = `${tasksUrl}/mine`;

export const authUrl = "/api/auth/authenticate";

export const analysisUrl = "/v1/detail/analysis-records";

export const externalUrl = "/v1/detail/external-records";

export const analysisSendToApprove =
  "/v1/detail/analysis-records:send-to-approve";

export const analysisApprove = "/v1/detail/analysis-records:approve";

export const analysisReject = "/v1/detail/analysis-records:reject";

export const analysisUpdate = "/v1/detail/analysis-records:update/batch";

export const externalUpdate = "/v1/detail/external-records:update/batch";

export const analysisType = "/v1/dictionary/analysis-type";

export const createAnalysis = "/v1/detail/analysis-records/batch";

export const createAtReport = "/v1/detail/analysis-records/create-report";

export const createExternal = "/v1/detail/external-records/batch";

export const defaultPageSize = {
  page: 0,
  size: 10,
};

export const defaultPageableContent = {
  content: [],
  pageable: {
    sort: {
      empty: true,
      sorted: true,
      unsorted: true,
    },
    offset: 0,
    paged: true,
    unpaged: true,
    pageNumber: 0,
    pageSize: 0,
  },
  last: true,
  totalElements: 0,
  totalPages: 0,
  first: true,
  number: 0,
  sort: {
    empty: true,
    sorted: true,
    unsorted: true,
  },
  size: 0,
  numberOfElements: 0,
  empty: true,
};
