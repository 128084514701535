import { AxiosResponse } from "axios";
import { IPageableRequest, IPageableResponse } from "./api";
import { usersUrl } from "./const";
import Http from "./https";

namespace UsersApi {
  export interface IResponseContent {
    id: string;
    userFullName: string;
    userProjectRole: string;
    userEmail: string;
    userAccessLevel: string;
    userLoad: string;
  }

  export interface IGetResponse extends IPageableResponse {
    content: IResponseContent[];
  }

  export interface IPostRequest {
    id: string;
    userFullName: string;
    userProjectRole: string;
    userEmail: string;
    userPassword: string;
    userAccessLevel: string;
    userLoad: string;
  }

  export const get = async (
    data?: IPageableRequest
  ): Promise<AxiosResponse<IGetResponse>> => {
    return await Http.Private.get(usersUrl, {
      params: data,
    });
  };

  export const getOne = async (
    id: string
  ): Promise<AxiosResponse<IResponseContent>> => {
    return await Http.Private.get(`${usersUrl}/${id}`);
  };

  export const post = async (data: IPostRequest) => {
    return await Http.Private.post(usersUrl, data);
  };

  export const put = async (data: IPostRequest) => {
    return await Http.Private.put(usersUrl, data);
  };

  export const deleteUserReq = async (id: string) => {
    return await Http.Private.put(`${usersUrl}/${id}`);
  };
}

export default UsersApi;
