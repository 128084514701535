import { RootState } from "@redux/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import AuthApi from "@api/auth";
import Api from "@api/index";

interface IState extends AuthApi.IMeResponse {
  isAuth: boolean;
  accessToken: string;
  isLoading: boolean;
  isExpired: boolean;
}

const initialState: IState = {
  id: "",
  userFullName: "",
  userProjectRole: "",
  userEmail: "",
  userAccessLevel: "",
  userLoad: "",
  isAuth: false,
  isLoading: false,
  isExpired: false,
  accessToken: "",
};

export const signIn = createAsyncThunk(
  "auth/signIn",
  async (payload: AuthApi.IRequest) => {
    const { data } = await Api.auth.signIn(payload);

    return data;
  }
);

export const me = createAsyncThunk("auth/me", async (_, { dispatch }) => {
  const { data } = await Api.auth.getMe();

  return data;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clear: () => initialState,
    setToken: (state, action: PayloadAction<string>) => ({
      ...state,
      accessToken: action.payload,
    }),
    setIsExpired: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isExpired: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        signIn.fulfilled,
        (state, action: PayloadAction<AuthApi.IResponse>) => {
          const token = `${action.payload.tokenType} ${action.payload.accessToken}`;
          localStorage.setItem("accessToken", token);
          return {
            ...state,
            accessToken: token,
          };
        }
      )
      .addCase(me.pending, (state) => {
        return {
          ...state,
          isLoading: true,
          isExpired: false,
        };
      })
      .addCase(
        me.fulfilled,
        (state, action: PayloadAction<AuthApi.IMeResponse>) => {
          return {
            ...state,
            ...action.payload,
            isAuth: true,
            isLoading: false,
            isExpired: false,
          };
        }
      )
      .addCase(me.rejected, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isExpired: action.error.message === "Expired",
        };
      });
  },
});

export const { clear, setToken, setIsExpired } = authSlice.actions;

export const authSelector = (state: RootState) => state.auth;

export default authSlice.reducer;
