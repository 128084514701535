import React, { FC, useEffect, useState } from "react";
import { Input, Table } from "antd";
import _ from "lodash";
import {
  ICurrentDate,
  ITask,
  ITaskDayActivities,
} from "@containers/MyTimesheets/Current/Current.types";
import styles from "./Grid.module.scss";
import "moment/locale/en-gb";
import moment from "moment";
import { weekDays } from "@helpers/const";
import Hooks from "@helpers/hooks";
import { ColumnsType } from "antd/lib/table";
// import Helpers from "@helpers/utils";

export interface ITableData {
  key: string;
  taskId: string;
  taskName: string;
  resRemainWork: number;
  mon: string;
  tue: string;
  wed: string;
  thu: string;
  fri: string;
  sat: string;
  sun: string;
  total: number;
}

interface IGrid {
  enterpriseId: string;
  timesheetId: string;
  tasks: ITask[];
  date: ICurrentDate;
  setTasks: React.Dispatch<React.SetStateAction<ITask[]>>;
  total: ITableData;
  setTotal: React.Dispatch<React.SetStateAction<ITableData>>;
}

const Grid: FC<IGrid> = ({
  enterpriseId,
  timesheetId,
  tasks,
  setTasks,
  date,
  total,
  setTotal,
}) => {
  const [dateWeeks, setDateWeeks] = useState<string[]>([]);
  const [tableData, setTableData] = useState<ITableData[]>([]);
  const { height } = Hooks.useWindowSize();

  const onTaskChange = (
    key: keyof ITaskDayActivities,
    taskIndex: number,
    dateWeek: string,
    value: string
  ) => {
    const reg = /^\d*(\.\d*)?$/;
    if (reg.test(value) || value === "") {
      setTasks((state) => {
        const item = tasks[taskIndex];
        const first = state.slice(0, taskIndex);
        const second = state.slice(taskIndex + 1);

        return [
          ...first,
          {
            ...item,
            dayActivities: {
              ...item.dayActivities,
              [key]: item.dayActivities[key]
                ? {
                    ...item.dayActivities[key],
                    activActWork: value,
                  }
                : {
                    activEnterpriseId: enterpriseId,
                    activRejectDetails: null,
                    activResourceId: tasks[taskIndex].resourceId,
                    activTimesheet: timesheetId,
                    taskId: tasks[taskIndex].taskId,
                    activDate: dateWeek,
                    activActWork: value,
                    activWeekday: weekDays[key],
                  },
            },
          },
          ...second,
        ];
      });
    }
  };

  const renderDate = (date: string) => {
    const day = date.slice(-3);
    const dayDate = date.slice(0, 2);
    const month = date.slice(3, -3);

    return (
      <div className={styles["dateHeader"]}>
        <span className={styles["day"]}>{day}</span>
        <span className={styles["dayDate"]}>{dayDate}</span>
        <span className={styles["mounth"]}>{month}</span>
      </div>
    );
  };

  const columns: ColumnsType<ITableData> = [
    {
      title: "Task",
      dataIndex: "taskName",
      key: "taskName",
      width: 190,
      render: (text, rexord, index) => (
        <div className={styles.cellTaskName} title={text}>
          {text}
        </div>
      ),
      // sorter: (a: ITableData, b: ITableData) =>
      //   Helpers.compare(a.taskName, b.taskName),
    },
    {
      title: "Remaining Time",
      dataIndex: "resRemainWork",
      key: "resRemainWork",
      width: 150,
    },
    {
      title: () => {
        return renderDate(
          moment(dateWeeks[0]).locale("en-gb").format("DD/MM ddd")
        );
      },
      dataIndex: "mon",
      key: "mon",
      width: 100,
      render: (text, record, index) => (
        <Input
          onChange={(e) =>
            onTaskChange("mon", index, dateWeeks[0], e.target.value)
          }
          value={text}
        />
      ),
    },
    {
      title: renderDate(
        moment(dateWeeks[1]).locale("en-gb").format("DD/MM ddd")
      ),
      dataIndex: "tue",
      key: "tue",
      width: 100,
      render: (text, record, index) => (
        <Input
          onChange={(e) =>
            onTaskChange("tue", index, dateWeeks[1], e.target.value)
          }
          value={text}
        />
      ),
    },
    {
      title: renderDate(
        moment(dateWeeks[2]).locale("en-gb").format("DD/MM ddd")
      ),
      dataIndex: "wed",
      key: "wed",
      width: 100,
      render: (text, record, index) => (
        <Input
          onChange={(e) =>
            onTaskChange("wed", index, dateWeeks[2], e.target.value)
          }
          value={text}
        />
      ),
    },
    {
      title: renderDate(
        moment(dateWeeks[3]).locale("en-gb").format("DD/MM ddd")
      ),
      dataIndex: "thu",
      key: "thu",
      width: 100,
      render: (text, record, index) => (
        <Input
          onChange={(e) =>
            onTaskChange("thu", index, dateWeeks[3], e.target.value)
          }
          value={text}
        />
      ),
    },
    {
      title: renderDate(
        moment(dateWeeks[4]).locale("en-gb").format("DD/MM ddd")
      ),
      dataIndex: "fri",
      key: "fri",
      width: 100,
      render: (text, record, index) => (
        <Input
          onChange={(e) =>
            onTaskChange("fri", index, dateWeeks[4], e.target.value)
          }
          value={text}
        />
      ),
    },
    {
      title: renderDate(
        moment(dateWeeks[5]).locale("en-gb").format("DD/MM ddd")
      ),
      dataIndex: "sat",
      key: "sat",
      width: 100,
      className: `${styles.cellGray}`,
      render: (text, record, index) => (
        <Input
          onChange={(e) =>
            onTaskChange("sat", index, dateWeeks[5], e.target.value)
          }
          value={text}
        />
      ),
    },
    {
      title: renderDate(
        moment(dateWeeks[6]).locale("en-gb").format("DD/MM ddd")
      ),
      dataIndex: "sun",
      key: "sun",
      width: 100,
      className: `${styles.cellGray}`,
      render: (text, record, index) => (
        <Input
          onChange={(e) =>
            onTaskChange("sun", index, dateWeeks[6], e.target.value)
          }
          value={text}
        />
      ),
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      fixed: "right",
      width: 82,
    },
  ];

  useEffect(() => {
    const newTasks = tasks.map((el) => {
      const dayActivitieKeys = Object.keys(el.dayActivities);
      const dayActivities = dayActivitieKeys.reduce(
        (prev, curr) => {
          const dayValue =
            el.dayActivities[curr as keyof ITaskDayActivities]?.activActWork ||
            "";
          return {
            ...prev,
            [curr]: dayValue,
            total: prev.total + +(dayValue || 0),
          };
        },
        {
          mon: "",
          tue: "",
          wed: "",
          thu: "",
          fri: "",
          sat: "",
          sun: "",
          total: 0,
        }
      );

      return {
        key: el.taskId,
        taskId: el.taskId,
        taskName: el.taskName,
        resRemainWork: el.resRemainWork || 0,
        ...dayActivities,
      };
    });

    const total = newTasks.reduce(
      (prev, curr) => {
        return {
          ...prev,
          mon: (+prev.mon + +curr.mon).toString(),
          tue: (+prev.tue + +curr.tue).toString(),
          wed: (+prev.wed + +curr.wed).toString(),
          thu: (+prev.thu + +curr.thu).toString(),
          fri: (+prev.fri + +curr.fri).toString(),
          sat: (+prev.sat + +curr.sat).toString(),
          sun: (+prev.sun + +curr.sun).toString(),
          total: prev.total + curr.total,
        };
      },
      {
        key: "total",
        taskId: "total",
        taskName: "Total",
        resRemainWork: 0,
        mon: "0",
        tue: "0",
        wed: "0",
        thu: "0",
        fri: "0",
        sat: "0",
        sun: "0",
        total: 0,
      }
    );
    setTotal(total);
    setTableData([...newTasks]);
  }, [tasks, setTotal]);

  useEffect(() => {
    const newDate = _.cloneDeep(date);

    setDateWeeks(
      Array(6)
        .fill(newDate.dateFrom)
        .reduce(
          (prev, curr) => {
            return [...prev, curr.add(1, "days").format("YYYY-MM-DD")];
          },
          [newDate.dateFrom.format("YYYY-MM-DD")]
        )
    );
  }, [date]);

  return (
    <Table
      className={styles.table}
      columns={columns}
      dataSource={tableData}
      pagination={false}
      scroll={{ y: height - 450 }}
      summary={() => (
        <Table.Summary fixed>
          <Table.Summary.Row className={styles.summary}>
            <Table.Summary.Cell
              className={`${styles.summaryCell} ${styles.summaryTitle}`}
              colSpan={2}
              index={0}
            >
              {total.taskName}
            </Table.Summary.Cell>
            <Table.Summary.Cell className={styles.summaryCell} index={1}>
              {total.mon}
            </Table.Summary.Cell>
            <Table.Summary.Cell className={styles.summaryCell} index={2}>
              {total.tue}
            </Table.Summary.Cell>
            <Table.Summary.Cell className={styles.summaryCell} index={3}>
              {total.wed}
            </Table.Summary.Cell>
            <Table.Summary.Cell className={styles.summaryCell} index={4}>
              {total.thu}
            </Table.Summary.Cell>
            <Table.Summary.Cell className={styles.summaryCell} index={5}>
              {total.fri}
            </Table.Summary.Cell>
            <Table.Summary.Cell className={styles.summaryCellGray} index={6}>
              {total.sat}
            </Table.Summary.Cell>
            <Table.Summary.Cell className={styles.summaryCellGray} index={6}>
              {total.sun}
            </Table.Summary.Cell>
            <Table.Summary.Cell className={styles.summaryCell} index={6}>
              {total.total}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        </Table.Summary>
      )}
      bordered
    />
  );
};

export default Grid;
