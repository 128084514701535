export const AUTH_PAGE = "/auth";
export const MY_TIMESHEETS_PAGE = "/my-timesheets";
export const TASK_CREATION_PAGE = "/task-creation";
export const ANALYSIS_TOOL_PAGE = "/analysis-tool";
export const ANALYSIS_APPROVE_PAGE = "/analysis-tool/analysis-approve";
export const EXTERNAL_TOOL_PAGE = "/external-tool";

export const CURRENT_TIMESHEETS_PAGE = `${MY_TIMESHEETS_PAGE}/current`;
export const ALL_TIMESHEETS_PAGE = `${MY_TIMESHEETS_PAGE}/all`;
export const CURRENT_PAGE = "/current";
export const ALL_PAGE = "/all";
