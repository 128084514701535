import React, { FC } from "react";
import { Layout as AntLayout } from "antd";
import styles from "./Layout.module.scss";
import Header from "./Header/Header";
import Sider from "./Sider/Sider";

interface LayoutProps {
  children:
    | string
    | number
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <AntLayout className={styles.layout}>
      <Header />
      <Sider>{children}</Sider>
    </AntLayout>
  );
};

export default Layout;
