import { defaultPageableContent } from "@api/const";
import { RootState } from "@redux/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import TimesheetApi from "@api/timesheet";
import Api from "@api/index";

const initialState: TimesheetApi.IGetResponse = {
  ...defaultPageableContent,
};

export const postCreateReport = createAsyncThunk(
  "timesheet/postCreateTimesheets",
  async (payload: TimesheetApi.ICreateReportRequest) => {
    const { data } = await Api.timesheet.createReport(payload);

    return data;
  }
);

export const getTimesheet = createAsyncThunk(
  "timesheet/getTimesheet",
  async (payload?: TimesheetApi.IGetRequest) => {
    const { data } = await Api.timesheet.get(payload);

    return data;
  }
);

export const timesheetSlice = createSlice({
  name: "timesheets",
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getTimesheet.fulfilled,
        (_, action: PayloadAction<TimesheetApi.IGetResponse>) => action.payload
      )
      .addCase(postCreateReport.fulfilled, (_, action: PayloadAction<Blob>) => {
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(action.payload);
        a.href = url;
        a.download = "report.xlsx";
        a.click();
      });
  },
});

export const { clear } = timesheetSlice.actions;

export const timesheetsSelector = (state: RootState) => state.timesheets;

export default timesheetSlice.reducer;
