import { RootState } from "@redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IState {
  id: string;
  userFullName: string;
}

const initialState: IState = {
  id: "",
  userFullName: "",
};

export const delegatedUserSlice = createSlice({
  name: "delegatedUser",
  initialState,
  reducers: {
    clear: () => initialState,
    setDelegatedUser: (state, action: PayloadAction<IState>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { clear, setDelegatedUser } = delegatedUserSlice.actions;

export const delegatedUserSelector = (state: RootState) => state.delegatedUser;

export default delegatedUserSlice.reducer;
