import { RootState } from "@redux/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import UsersApi from "@api/users";
import Api from "@api/index";

const initialState: UsersApi.IResponseContent = {
  id: "",
  userFullName: "",
  userProjectRole: "",
  userEmail: "",
  userAccessLevel: "",
  userLoad: "",
};

export const getOneUser = createAsyncThunk(
  "selectedUser/getOneUser",
  async (payload: string) => {
    const { data } = await Api.users.getOne(payload);

    return data;
  }
);

export const selectedUserSlice = createSlice({
  name: "selectedUser",
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      getOneUser.fulfilled,
      (_, action: PayloadAction<UsersApi.IResponseContent>) => {
        return action.payload;
      }
    );
  },
});

export const { clear } = selectedUserSlice.actions;

export const selectedUserSelector = (state: RootState) => state.selectedUser;

export default selectedUserSlice.reducer;
