import { Button, Form, Select, Typography } from "antd";
import React, { FC, useEffect, useState, useCallback } from "react";
import styles from "../ExternalTool.module.scss";
import {
  ClearOutlined,
  LeftOutlined,
  RightOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { authSelector } from "@redux/slices/auth";
import { getTasks, tasksSelector } from "@redux/slices/tasks";
import { getProjects, projectsSelector } from "@redux/slices/projects";
import { ICurrentDate, IFilter } from "../ExternalTool.types";
import { getExternal } from "@redux/slices/external";
import _ from "lodash";
import { getUsers } from "@redux/slices/users";
import externalApi from "@api/external";
import { IPageableRequest } from "@api/api";

const dateFormat = "DD-MM-YYYY";

interface ISearch {
  pagination: IPageableRequest;
  external: externalApi.IGetResponse;
  date: ICurrentDate;
  setDate: React.Dispatch<React.SetStateAction<ICurrentDate>>;
  filters: IFilter;
  defaultFilters?: any;
  setFilters: React.Dispatch<React.SetStateAction<any>>;
}

interface IForm {
  analysisProjectName: string;
  analysisTaskId: string;
  dateStart: string;
  dateEnd: string;
}

const ExternalSearch: FC<ISearch> = ({
  pagination,
  external,
  date,
  setDate,
  filters,
  setFilters,
  defaultFilters,
}) => {
  const { Option } = Select;
  const [form] = Form.useForm<IForm>();
  const { id } = useAppSelector(authSelector);
  const tasks = useAppSelector(tasksSelector);
  const projects = useAppSelector(projectsSelector);
  const [selectedProject, setSelectedProject] = useState("");
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      getUsers({
        page: 0,
        size: 1000,
      })
    );
  }, [dispatch]);

  const onSearch = useCallback(async () => {
    await form.validateFields();

    const requestDate = _.cloneDeep(date);

    dispatch(
      getExternal({
        page: pagination.page - 1,
        size: pagination.size,
        startDate: requestDate.dateFrom.format("YYYY-MM-DD"),
        endDate: requestDate.dateTo.format("YYYY-MM-DD"),
        ...defaultFilters,
        ...filters,
      })
    );
  }, [date, defaultFilters, dispatch, filters, form, pagination]);

  const onPrevDate = () => {
    setDate({
      dateFrom: moment(date.dateFrom, dateFormat).subtract(7, "days"),
      dateTo: moment(date.dateTo, dateFormat).subtract(7, "days"),
    });
  };

  const onNextDate = () => {
    setDate({
      dateFrom: moment(date.dateFrom, dateFormat).add(7, "days"),
      dateTo: moment(date.dateTo, dateFormat).add(7, "days"),
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      dateStart: moment(date.dateFrom, dateFormat).format("DD MM YYYY"),
      dateEnd: moment(date.dateTo, dateFormat).format("DD MM YYYY"),
    });
  }, [date, form]);

  const onProjectChange = (e: any) => {
    if (e.length) {
      projects.content.map((item) => {
        if (item.id === e) {
          return setSelectedProject(item.projTitle);
        } else return "";
      });
    }
    setFilters((state: any) => ({
      ...state,
      projectId: form.getFieldValue("analysisProjectName"),
    }));
  };

  useEffect(() => {
    dispatch(
      getTasks({
        taskType: "TASK",
        projectName: selectedProject,
        userEnterpriseId: id,
        page: 0,
        size: 1000,
      })
    );
  }, [dispatch, selectedProject, id]);

  useEffect(() => {
    dispatch(getProjects({ size: 1000, page: 0 }));
  }, [dispatch]);

  const onSelectClick = () => {
    setFilters((state: any) => ({
      ...state,
      taskId: form.getFieldValue("analysisTaskId"),
    }));
  };

  const onClear = useCallback(() => {
    setFilters({});
    const requestDate = _.cloneDeep(date);
    dispatch(
      getExternal({
        page: pagination.page - 1,
        size: pagination.size,
        startDate: requestDate.dateFrom.format("YYYY-MM-DD"),
        endDate: requestDate.dateTo.format("YYYY-MM-DD"),
        ...defaultFilters,
      })
    );
    setSelectedProject("");
    form.resetFields();
  }, [date, defaultFilters, dispatch, form, pagination, setFilters]);

  return (
    <Form
      form={form}
      name="searchForm"
      className={styles.searchWrapper}
      autoComplete="off"
    >
      <div className={styles["date-block"]}>
        <div
          style={{
            display: "flex",
          }}
        >
          <Button shape="circle" onClick={onPrevDate} icon={<LeftOutlined />} />
          <div className={styles["date-info"]}>
            <Form.Item noStyle name="dateStart">
              <Typography.Title level={5}>
                {date.dateFrom.format("MMM DD YY")}
              </Typography.Title>
            </Form.Item>
            <span>—</span>
            <Form.Item noStyle name="dateEnd">
              <Typography.Title level={5}>
                {date.dateTo.format("MMM DD YY")}
              </Typography.Title>
            </Form.Item>
          </div>
          <Button
            shape="circle"
            onClick={onNextDate}
            icon={<RightOutlined />}
          />
        </div>
      </div>
      <Form.Item noStyle name="analysisProjectName">
        <Select
          placeholder="Project Name"
          onChange={onProjectChange}
          className={styles.searchFilter}
          showSearch
          allowClear
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {projects.content.map((el) => (
            <Option key={el.id} value={el.id}>
              {el.projTitle}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item noStyle name="analysisTaskId">
        <Select
          placeholder="Tasks"
          className={styles.searchFilter}
          onClick={onSelectClick}
          showSearch
          allowClear
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {selectedProject.length &&
            tasks.content.map((el) => (
              <Option key={el.id} value={el.id}>
                {el.taskName}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Button type="primary" icon={<SearchOutlined />} onClick={onSearch}>
        Search
      </Button>
      <Button type="default" icon={<ClearOutlined />} onClick={onClear}>
        Clear
      </Button>
    </Form>
  );
};

export default ExternalSearch;
