export function promiseAny(promises: Promise<unknown>[]): Promise<void> {
  return new Promise((resolve) => {
    let count = 0;
    promises.forEach((promise) =>
      promise.finally(() => {
        count++;
        if (count === promises.length) {
          resolve();
        }
      })
    );
  });
}
