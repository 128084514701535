import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import { defaultCreateNewExternalState } from "@containers/ExternalTool/ExternalTool";
import {
  IExternalData,
  ICurrentDate,
  IDrawerCreate,
  IFilter,
  INewExternalData,
  INewExternalRecord,
} from "@containers/ExternalTool/ExternalTool.types";
import { getTasks, tasksSelector } from "@redux/slices/tasks";
import { projectsSelector } from "@redux/slices/projects";
import Hooks from "@helpers/hooks";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Select,
  Table,
  Input,
  Popconfirm,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { FC, useCallback, useEffect, useState } from "react";
import styles from "./CreateNewExternal.module.scss";
import {
  getResource,
  taskResourcesSelector,
} from "@redux/slices/taskResources";
import {
  getExternal,
  postExternal,
  updateExternal,
} from "@redux/slices/external";
import _ from "lodash";
import { authSelector } from "@redux/slices/auth";
import moment from "moment";
import TasksApi from "@api/tasks";
import { PayloadAction } from "@reduxjs/toolkit";
import externalApi from "@api/external";
import locale from "antd/lib/date-picker/locale/ru_RU";

interface ICreateNewExternal {
  createNewExternalState: IDrawerCreate;
  dates: ICurrentDate;
  filters: IFilter;
  defaultFilters?: any;
  setCreateNewExternalState: React.Dispatch<
    React.SetStateAction<IDrawerCreate>
  >;
  selectedRows: IExternalData;
  setSelectedRows: React.Dispatch<React.SetStateAction<IExternalData>>;
}

interface IRowTasksSearch {
  taskName: string;
  record: INewExternalRecord | null;
}

const defaultTableData: INewExternalData = [
  {
    analysisRecordId: `${new Date().getTime()}`,
    analysisDate: "",
    analysisActWork: 0,
    analysisDescription: "",
    analysisResource: 0,
    analysisRowTasks: [],
  },
];

const CreateNewExternal: FC<ICreateNewExternal> = ({
  createNewExternalState,
  setCreateNewExternalState,
  dates,
  filters,
  selectedRows,
  setSelectedRows,
  defaultFilters,
}) => {
  const { height, width } = Hooks.useWindowSize();
  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const tasks = useAppSelector(tasksSelector);
  const projects = useAppSelector(projectsSelector);
  const dispatch = useAppDispatch();
  const [tableData, setTableData] =
    useState<INewExternalData>(defaultTableData);
  const { taskResources } = useAppSelector(taskResourcesSelector);
  const [currentRow, setCurrentRow] = useState("");
  const { id } = useAppSelector(authSelector);
  const [rowTaskSearch, setRowTaskSearch] = useState<IRowTasksSearch>({
    taskName: "",
    record: null,
  });

  const visibleChange = () => {
    dispatch(
      getTasks({
        taskType: "TASK",
        page: 0,
        userEnterpriseId: id,
        size: 1000,
      })
    );
  };

  const onClose = () => {
    setSelectedRows([]);
    setCreateNewExternalState(defaultCreateNewExternalState);
    form.resetFields();
    setTableData(defaultTableData);
  };

  const getEditTableData = useCallback(() => {
    return selectedRows.map(async (el) => {
      const project = projects.content.find(
        (elem) => elem.projTitle === el.projectName
      );

      const values = tasks.content.reduce(
        (prev: TasksApi.IGeResponseContent[], curr) =>
          curr.project?.id === project?.id ? [...prev, curr] : prev,
        []
      );

      const task = values.find((elem) => elem.taskName === el.taskName);

      form.setFieldsValue({
        [`analysisDate-${el.analysisRecordId}`]: moment(el.date),
        [`analysisProjectId-${el.analysisRecordId}`]: project?.id,
        [`task-${el.analysisRecordId}`]: task?.taskName || "",
        [`effort-${el.analysisRecordId}`]: el.effort,
        [`description-${el.analysisRecordId}`]: el.description,
      });

      const taskResource = (await dispatch(
        getResource(task?.id || "")
      )) as PayloadAction<TasksApi.IResource[]>;

      return {
        analysisRecordId: el.analysisRecordId,
        analysisDate: el.date,
        analysisRowTasks: values,
        analysisProjectId: project?.id,
        analysisResource:
          taskResource.payload.find((el) => el.resEnterpriseId === id)?.id || 0,
        analysisActWork: el.effort,
        analysisDescription: el.description,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, form, projects.content, tasks.content]);

  useEffect(() => {
    if (createNewExternalState.isEdit) {
      (async () => {
        const newTableData = await Promise.all(getEditTableData());

        setTableData(newTableData);
      })();
    }
  }, [createNewExternalState.isEdit, getEditTableData]);

  const onExternalChange = (
    dataIndex: string,
    index: number,
    value: string | number
  ) => {
    const newData = tableData.map((item, itemIndex) =>
      index === itemIndex
        ? {
            ...item,
            [dataIndex]: value,
          }
        : item
    );
    setTableData(newData);
  };

  const onTasksChange = useCallback(
    async (value: string, record: INewExternalRecord) => {
      const recordCurrentData = tableData.find(
        (item) => item.analysisRecordId === record.analysisRecordId
      );

      const taskId = recordCurrentData?.analysisRowTasks.find(
        (el: any) => el.taskName === value
      ).id;

      setCurrentRow(record.analysisRecordId); //id записи в которой выбрали таск, чтобы в tableData к нужной записи добавить resources

      //ищем resources для выбранного в строке таска
      if (taskId) {
        await dispatch(getResource(taskId));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableData]
  );

  const onProjectChange = (
    dataIndex: string,
    index: number,
    value: string,
    record: INewExternalRecord
  ) => {
    const projectName = projects.content.find(
      (item) => item.id === value
    )?.projTitle;

    const values = tasks.content.reduce(
      (prev: TasksApi.IGeResponseContent[], curr) =>
        curr.project?.id === value ? [...prev, curr] : prev,
      []
    );

    const newData = tableData.map((item, itemIndex) =>
      index === itemIndex
        ? {
            ...item,
            [dataIndex]: value,
            analysisRowTasks: values,
            analysisProjectName: projectName,
          }
        : item
    );

    setTableData(newData);
    if (values.length === 1) {
      // onTasksChange(values[0].taskName, record);
      setRowTaskSearch({ taskName: values[0].taskName, record: record });

      form.setFieldsValue({
        [`task-${record.analysisRecordId}`]: values[0].taskName,
      });
    } else {
      form.setFieldsValue({
        [`task-${record.analysisRecordId}`]: null,
      });
    }
  };

  useEffect(() => {
    if (rowTaskSearch.record) {
      onTasksChange(rowTaskSearch.taskName, rowTaskSearch.record);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowTaskSearch]);

  const onSave = async () => {
    await form.validateFields();
    form.submit();

    let needToClose = true;

    const postContent: externalApi.IPostContent = tableData.map(
      (item: INewExternalRecord) => ({
        analysisRecordId: createNewExternalState.isEdit
          ? item.analysisRecordId
          : undefined,
        analysisResource: item.analysisResource,
        analysisActWork: Number(item.analysisActWork),
        analysisDate: item.analysisDate,
        analysisDescription: item.analysisDescription,
      })
    );

    const requestDate = _.cloneDeep(dates);

    const date: externalApi.IPostParams = {
      dateStart: requestDate.dateFrom.format("YYYY-MM-DD"),
      dateFinish: requestDate.dateTo.format("YYYY-MM-DD"),
    };

    const postRequestData: externalApi.IPostRequest = {
      content: postContent,
      dates: date,
    };

    if (createNewExternalState.isEdit) {
      await dispatch(updateExternal(postRequestData));
    } else {
      const res = await dispatch(postExternal(postRequestData));

      if ((res as any).error) {
        needToClose = false;
      }
    }

    if (needToClose) {
      onClose();

      dispatch(
        getExternal({
          startDate: requestDate.dateFrom.format("YYYY-MM-DD"),
          endDate: requestDate.dateTo.format("YYYY-MM-DD"),
          page: 0,
          size: 100,
          ...filters,
          ...defaultFilters,
        })
      );
    }
  };

  const onClear = useCallback(() => {
    form.resetFields();

    if (!createNewExternalState.isEdit) {
      setTableData(defaultTableData);
    }
  }, [form, createNewExternalState.isEdit]);

  const onAdd = () => {
    const key = new Date().getTime();

    setTableData(() => [
      ...tableData,
      {
        analysisRecordId: `${key}`,
        analysisDate: "",
        analysisActWork: 0,
        analysisDescription: "",
        analysisResource: 0,
        analysisRowTasks: [],
      },
    ]);
  };

  useEffect(() => {
    //добавляем resources в tableData
    if (taskResources.length && currentRow !== "") {
      const newData = tableData.map((item: any) =>
        item.analysisRecordId === currentRow
          ? {
              ...item,
              analysisResource:
                taskResources.find((el) => el.resEnterpriseId === id)?.id || 0,
            }
          : item
      );
      setTableData(newData);
    } else if (currentRow !== "") {
      const newData = tableData.map((item: any) =>
        item.analysisRecordId === currentRow
          ? {
              ...item,
              analysisResource: 0,
            }
          : item
      );
      setTableData(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskResources]);

  const handleDelete = (key: any) => {
    const names = form.getFieldsValue(); // получаем все имена итемов формы
    const clearFields = Object.keys(names).filter((item) =>
      item.includes(`${key}`)
    ); // ищем имена тех итемов которые надо очистить
    const newData = tableData.filter((item) => item.analysisRecordId !== key); // ищем данные которые надо удалить из tableData
    form.resetFields(clearFields); // очищаем нужные поля формы
    setTableData(newData); //  обновляем tableData
  };

  const handleCopy = (key: any) => {
    const copyData = tableData.filter((item) => item.analysisRecordId === key);

    const newKey = new Date().getTime();

    setTableData(() => [
      ...tableData,
      {
        analysisRecordId: `${newKey}`,
        analysisDate: `${copyData[0].analysisDate}`,
        analysisActWork: 0,
        analysisProjectId: `${copyData[0].analysisProjectId}`,
        analysisProjectName: `${copyData[0].analysisProjectName}`,
        analysisDescription: "",
        analysisResource: copyData[0].analysisResource,
        analysisRowTasks: copyData[0].analysisRowTasks,
      },
    ]);

    const formData = form.getFieldsValue();

    form.setFieldsValue({
      [`analysisDate-${newKey}`]:
        formData[`analysisDate-${key}`] === null ||
        formData[`analysisDate-${key}`] === undefined ||
        formData[`analysisDate-${key}`] === ""
          ? ""
          : moment(formData[`analysisDate-${key}`]),
      [`analysisProjectId-${newKey}`]: formData[`analysisProjectId-${key}`],
      [`task-${newKey}`]: formData[`task-${key}`],
    });
  };

  const columns: ColumnsType<INewExternalRecord> = [
    {
      title: "Date",
      dataIndex: "analysisDate",
      key: "analysisDate",
      width: 150,
      render: (_text, record, index) => (
        <Form.Item
          name={`analysisDate-${record.analysisRecordId}`}
          className={styles.formItem}
          rules={[{ required: true, message: "Required" }]}
        >
          <DatePicker
            locale={locale}
            format="YYYY-MM-DD"
            onChange={(_date, dateString) =>
              onExternalChange("analysisDate", index, dateString)
            }
          />
        </Form.Item>
      ),
    },
    {
      title: "Project",
      dataIndex: "analysisProjectId",
      key: "analysisProjectId",
      width: 150,
      render: (_text, record, index) => (
        <Form.Item
          name={`analysisProjectId-${record.analysisRecordId}`}
          className={styles.formItem}
          rules={[{ required: true, message: "Required" }]}
        >
          <Select
            placeholder="projects"
            className={styles.newExternalItem}
            onChange={(value) =>
              onProjectChange("analysisProjectId", index, value, record)
            }
            showSearch
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {projects.content.map((el: any) => (
              <Option key={el.id} value={el.analysisProjectId}>
                {el.projTitle}
              </Option>
            ))}
          </Select>
        </Form.Item>
      ),
    },
    {
      title: "Task",
      dataIndex: "analysisResource",
      key: "analysisResource",
      width: 300,
      render: (_text, record) => (
        <Form.Item
          name={`task-${record.analysisRecordId}`}
          className={styles.formItem}
          rules={[{ required: true, message: "Required" }]}
        >
          <Select
            placeholder="tasks"
            className={styles.newExternalItem}
            onChange={(value) => onTasksChange(value, record)}
            showSearch
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {record.analysisRowTasks.length
              ? record.analysisRowTasks.map((el: any) => (
                  <Option key={el.id} value={el.taskName}>
                    {el.taskName}
                  </Option>
                ))
              : []}
          </Select>
        </Form.Item>
      ),
    },
    {
      title: "Effort, h",
      dataIndex: "analysisActWork",
      key: "analysisActWork",
      width: 100,
      render: (text, record, index) => (
        <Form.Item
          name={`effort-${record.analysisRecordId}`}
          className={styles.formItem}
          rules={[{ required: true, message: "Required" }]}
        >
          <Input
            placeholder="effort"
            type="number"
            onChange={(e) =>
              onExternalChange("analysisActWork", index, e.target.value)
            }
            value={text}
            className={styles.newExternalItem}
          />
        </Form.Item>
      ),
    },
    {
      title: "Description",
      dataIndex: "analysisDescription",
      key: "analysisDescription",
      width: 300,
      render: (text, record, index) => (
        <Form.Item
          name={`description-${record.analysisRecordId}`}
          className={styles.formItem}
          rules={[{ required: true, message: "Required" }]}
        >
          <TextArea
            spellCheck
            placeholder="description"
            autoSize
            onChange={(e) =>
              onExternalChange("analysisDescription", index, e.target.value)
            }
            value={text}
            className={styles.newExternalItem}
          />
        </Form.Item>
      ),
    },
    {
      title: "",
      dataIndex: "copyLine",
      width: 30,
      render: (_, record) =>
        !createNewExternalState.isEdit ? (
          <CopyOutlined onClick={() => handleCopy(record.analysisRecordId)} />
        ) : null,
    },
    {
      title: "",
      dataIndex: "operation",
      width: 30,
      render: (_, record) =>
        tableData.length > 1 && !createNewExternalState.isEdit ? (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.analysisRecordId)}
          >
            <DeleteOutlined />
          </Popconfirm>
        ) : null,
    },
  ];

  return (
    <Drawer
      width={width - 250}
      visible={createNewExternalState.isVisible}
      onClose={onClose}
      destroyOnClose={true}
      title={`${createNewExternalState.isEdit ? "Edit" : "New"} external`}
      afterVisibleChange={visibleChange}
    >
      <div className={styles.newExternalWrapper}>
        <Form form={form} name="newExternal">
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            size="small"
            scroll={{ y: height - 200 }}
            bordered
          ></Table>
        </Form>
        <div className={styles.newExternalActions}>
          <Button type="primary" onClick={onSave}>
            Save
          </Button>
          {!createNewExternalState.isEdit && (
            <Button type="dashed" onClick={onAdd}>
              + Add row
            </Button>
          )}
          <Button onClick={onClear}>Clear</Button>
        </div>
      </div>
    </Drawer>
  );
};

export default CreateNewExternal;
