import { AxiosResponse } from "axios";
import { IPageableRequest, IPageableResponse } from "./api";
import {
  analysisUrl,
  createExternal,
  externalUpdate,
  externalUrl,
} from "./const";
import Http from "./https";
import ProjectsApi from "./projects";
import TasksApi from "./tasks";

namespace externalApi {
  export interface IGetResponseContent {
    analysisRecordId: string;
    date: string;
    projectName: string;
    taskName: string;
    actionType: IAnalysisType;
    effort: number;
    description: string;
    status: string;
    rejectComment: string;
    analysisApprover: string;
    analysisExecutor: string;
  }

  export interface IGetResponse extends IPageableResponse {
    content: IGetResponseContent[];
  }

  export interface IPostData {
    analysisRecordId?: string;
    analysisResource: number;
    analysisActWork: number;
    analysisDate: string;
    analysisDescription: string;
  }

  export interface IPostContent extends Array<IPostData> {}

  export interface IPostParams {
    dateStart: string;
    dateFinish: string;
  }

  export interface IPostRequest {
    content: IPostContent;
    dates: IPostParams;
  }

  export interface IDelRequest {
    dates: IPostParams;
    content: string[];
  }

  export interface IAnalysis {
    analysisRecordId: string; // required
    analysisResource: TasksApi.IResource;
    analysisActWork: number; //required
    analysisActionType: IAnalysisType;
    analysisDate: string; // required
    analysisDescription: string; // required
    analysisComment: string; // optional
    analsysisStatus: string; // required
    analysisApprover: string; // optional
    project: ProjectsApi.IResponseContent;
    task: TasksApi.IGeResponseContent;
  }

  export interface IAnalysisResponse extends Array<IAnalysis> {}

  export interface IGetRequest extends IPageableRequest {
    startDate: string;
    endDate: string;
    projectId?: string;
    analysisExecutor?: string;
    analysisApprover?: string;
    taskId?: string;
    status?: string[];
  }

  export interface IPatchRequest extends Array<string> {}

  export interface IGetAnalysisType extends Array<IAnalysisType> {}

  export interface IAnalysisType {
    id: string;
    actionTypeShortName: string;
    actionTypeFullName: string;
  }

  export const get = async (
    data: IGetRequest
  ): Promise<AxiosResponse<IGetResponse>> => {
    const { status, ...params } = data;
    return await Http.Private.get(
      `${analysisUrl}${status?.length ? `?status=${status[0]}` : ""}`,
      {
        params,
      }
    );
  };

  export const putUpdate = async (
    data: IPostRequest
  ): Promise<AxiosResponse<IAnalysisResponse>> => {
    return await Http.Private.put(
      externalUpdate +
        `?dateStart=${data.dates.dateStart}&dateFinish=${data.dates.dateFinish}`,
      data.content
    );
  };

  export const post = async (data: IPostRequest) => {
    return await Http.Private.post(
      createExternal +
        `?dateStart=${data.dates.dateStart}&dateFinish=${data.dates.dateFinish}`,
      data.content
    );
  };

  export const deleteItems = async (data: IDelRequest) => {
    return await Http.Private.delete(
      externalUrl +
        `?dateStart=${data.dates.dateStart}&dateFinish=${data.dates.dateFinish}`,
      {
        data: data.content,
      }
    );
  };
}

export default externalApi;
