import React, { useCallback, useMemo } from "react";
import { Layout, Typography, Menu, Button, Dropdown } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { authSelector, clear } from "@redux/slices/auth";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import styles from "./Header.module.scss";
import {
  delegatedUserSelector,
  clear as clearDelegated,
} from "@redux/slices/delegatedUser";

const Header = () => {
  const { userFullName, isAuth, userEmail } = useAppSelector(authSelector);
  const delegated = useAppSelector(delegatedUserSelector);
  const dispatch = useAppDispatch();

  const signOut = useCallback(() => {
    dispatch(clear());
    localStorage.clear();
  }, [dispatch]);

  const stopDelegate = useCallback(() => {
    dispatch(clearDelegated());
  }, [dispatch]);

  const menu = useMemo(() => {
    const defaultMenu = [
      {
        label: <Typography.Text>{userEmail}</Typography.Text>,
        key: "0",
      },
      {
        label: (
          <Button danger type="link" href="/auth" onClick={signOut} block>
            Sign Out
          </Button>
        ),
        key: "4",
      },
    ];

    const delegatedMenu = [
      {
        label: (
          <Typography.Text>{`Delegated as ${delegated.userFullName}`}</Typography.Text>
        ),
        key: "2",
      },
      {
        label: (
          <Button danger type="link" onClick={stopDelegate} block>
            Stop delegate
          </Button>
        ),
        key: "3",
      },
    ];

    const sortedMenu = [...defaultMenu, ...delegatedMenu].sort(
      (a, b) => +a.key - +b.key
    );

    return (
      <Menu
        className={styles.userMenu}
        items={delegated.id ? sortedMenu : defaultMenu}
      />
    );
  }, [userEmail, signOut, delegated, stopDelegate]);

  return (
    <Layout.Header className={styles.header}>
      <Typography.Title>Timesheet</Typography.Title>
      {isAuth && (
        <div className={styles["user-box"]}>
          <Typography.Text>{userFullName}</Typography.Text>
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button
              onClick={(e) => e.preventDefault()}
              shape="circle"
              size="large"
              icon={<UserOutlined />}
            />
          </Dropdown>
        </div>
      )}
    </Layout.Header>
  );
};

export default Header;
