import { Button, Form, Select, Typography } from "antd";
import React, { FC, useEffect, useState, useCallback } from "react";
import styles from "../AnalysisTool.module.scss";
import {
  ClearOutlined,
  LeftOutlined,
  RightOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { authSelector } from "@redux/slices/auth";
import { getTasks, tasksSelector } from "@redux/slices/tasks";
import { getProjects, projectsSelector } from "@redux/slices/projects";
import { ICurrentDate, IFilter } from "../AnalysisTool.types";
import { getAnalysis } from "@redux/slices/analysis";
import _ from "lodash";
import { getUsers, usersSelector } from "@redux/slices/users";
import analysisApi from "@api/analysis";
import { IPageableRequest } from "@api/api";
import Helpers from "@helpers/utils";

const dateFormat = "DD-MM-YYYY";

interface ISearch {
  isApprove?: boolean;
  pagination: IPageableRequest;
  analysis: analysisApi.IGetResponse;
  date: ICurrentDate;
  setDate: React.Dispatch<React.SetStateAction<ICurrentDate>>;
  filters: IFilter;
  defaultFilters?: any;
  setFilters: React.Dispatch<React.SetStateAction<any>>;
}

interface IForm {
  analysisProjectName: string;
  analysisStatus: string[];
  analysisExecutor: string;
  analysisTaskId: string;
  analysisActionType: string;
  dateStart: string;
  dateEnd: string;
}

interface IOption {
  label: string;
  value: string;
}

const AnalysisSearch: FC<ISearch> = ({
  isApprove = false,
  pagination,
  analysis,
  date,
  setDate,
  filters,
  setFilters,
  defaultFilters,
}) => {
  const { Option } = Select;
  const [form] = Form.useForm<IForm>();
  const { id } = useAppSelector(authSelector);
  const tasks = useAppSelector(tasksSelector);
  const projects = useAppSelector(projectsSelector);
  const users = useAppSelector(usersSelector);
  const [selectedProject, setSelectedProject] = useState("");
  const [actionTypeOptions, setActionTypeOptions] = useState<IOption[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    form.setFieldsValue({
      analysisStatus: defaultFilters.status,
      analysisExecutor: defaultFilters.analysisExecutor,
    });
  }, [defaultFilters, form]);

  useEffect(() => {
    const options = analysis.content.reduce((prev: IOption[], curr) => {
      if (prev.find((el) => el.value === curr.actionType.id)) {
        return prev;
      }
      return [
        ...prev,
        {
          label: curr.actionType.actionTypeFullName,
          value: curr.actionType.id,
        },
      ];
    }, []);
    setActionTypeOptions(options);
  }, [analysis]);

  useEffect(() => {
    dispatch(
      getUsers({
        page: 0,
        size: 1000,
      })
    );
  }, [dispatch]);

  const analysisStatus = [
    { key: "DRAFT", label: "draft" },
    { key: "APPROVED", label: "approved" },
    { key: "WAITING_APPROVE", label: "waiting approve" },
    { key: "REJECTED", label: "rejected" },
  ];

  const onSearch = useCallback(async () => {
    await form.validateFields();

    const requestDate = _.cloneDeep(date);

    dispatch(
      getAnalysis({
        page: pagination.page - 1,
        size: pagination.size,
        startDate: requestDate.dateFrom.format("YYYY-MM-DD"),
        endDate: requestDate.dateTo.format("YYYY-MM-DD"),
        ...defaultFilters,
        ...filters,
      })
    );
  }, [date, defaultFilters, dispatch, filters, form, pagination]);

  const onPrevDate = () => {
    setDate({
      dateFrom: moment(date.dateFrom, dateFormat).subtract(7, "days"),
      dateTo: moment(date.dateTo, dateFormat).subtract(7, "days"),
    });
  };

  const onNextDate = () => {
    setDate({
      dateFrom: moment(date.dateFrom, dateFormat).add(7, "days"),
      dateTo: moment(date.dateTo, dateFormat).add(7, "days"),
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      dateStart: moment(date.dateFrom, dateFormat).format("DD MM YYYY"),
      dateEnd: moment(date.dateTo, dateFormat).format("DD MM YYYY"),
    });
  }, [date, form]);

  const onProjectChange = (e: any) => {
    if (e && e.length) {
      projects.content.map((item) => {
        if (item.id === e) {
          return setSelectedProject(item.projTitle);
        } else return "";
      });
    }

    setFilters((state: any) => ({
      ...state,
      projectId: form.getFieldValue("analysisProjectName"),
    }));
  };

  useEffect(() => {
    dispatch(
      getTasks({
        taskType: "TASK",
        projectName: selectedProject,
        userEnterpriseId: id,
        page: 0,
        size: 1000,
      })
    );
  }, [dispatch, selectedProject, id]);

  useEffect(() => {
    dispatch(getProjects({ size: 1000, page: 0 }));
  }, [dispatch]);

  const onTask = () => {
    setFilters((state: any) => ({
      ...state,
      taskId: form.getFieldValue("analysisTaskId"),
    }));
  };

  const onActionType = () => {
    const item = actionTypeOptions.find(
      (el: any) => el.label === form.getFieldValue("analysisActionType")
    );

    setFilters((state: any) => ({
      ...state,
      analysisActionType: item?.value,
    }));
  };

  const onStatus = () => {
    setFilters((state: any) => ({
      ...state,
      status: form.getFieldValue("analysisStatus"),
    }));
  };

  const onExecutorChange = () => {
    setFilters((state: any) => ({
      ...state,
      analysisExecutor: form.getFieldValue("analysisExecutor"),
    }));
  };

  const onClear = useCallback(() => {
    setFilters({});
    const requestDate = _.cloneDeep(date);
    dispatch(
      getAnalysis({
        page: pagination.page - 1,
        size: pagination.size,
        startDate: requestDate.dateFrom.format("YYYY-MM-DD"),
        endDate: requestDate.dateTo.format("YYYY-MM-DD"),
        ...defaultFilters,
      })
    );
    setSelectedProject("");
    form.resetFields();
    form.setFieldsValue({
      analysisStatus: defaultFilters.status,
      analysisExecutor: defaultFilters.analysisExecutor,
    });
  }, [date, defaultFilters, dispatch, form, pagination, setFilters]);

  return (
    <Form
      form={form}
      name="searchForm"
      className={styles.searchWrapper}
      autoComplete="off"
    >
      <div className={styles["date-block"]}>
        <div
          style={{
            display: "flex",
          }}
        >
          <Button shape="circle" onClick={onPrevDate} icon={<LeftOutlined />} />
          <div className={styles["date-info"]}>
            <Form.Item noStyle name="dateStart">
              <Typography.Title level={5}>
                {date.dateFrom.format("MMM DD YY")}
              </Typography.Title>
            </Form.Item>
            <span>—</span>
            <Form.Item noStyle name="dateEnd">
              <Typography.Title level={5}>
                {date.dateTo.format("MMM DD YY")}
              </Typography.Title>
            </Form.Item>
          </div>
          <Button
            shape="circle"
            onClick={onNextDate}
            icon={<RightOutlined />}
          />
        </div>
      </div>
      <Form.Item noStyle name="analysisProjectName">
        <Select
          placeholder="Project Name"
          onChange={onProjectChange}
          className={styles.searchFilter}
          showSearch
          allowClear
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {_.clone(projects.content)
            .sort((a, b) => Helpers.compare(a.projTitle, b.projTitle))
            .map((el) => (
              <Option key={el.id} value={el.id}>
                {el.projTitle}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item noStyle name="analysisTaskId">
        <Select
          placeholder="Tasks"
          className={styles.searchFilter}
          onChange={onTask}
          showSearch
          allowClear
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
        >
          {selectedProject.length &&
            _.clone(tasks.content)
              .sort((a, b) => Helpers.compare(a.taskName, b.taskName))
              .map((el) => (
                <Option key={el.id} value={el.id}>
                  {el.taskName}
                </Option>
              ))}
        </Select>
      </Form.Item>
      <Form.Item noStyle name="analysisActionType">
        <Select
          placeholder="Action type"
          className={styles.searchFilter}
          onChange={onActionType}
          allowClear
          showSearch
        >
          {_.clone(actionTypeOptions)
            .sort((a, b) => Helpers.compare(a.label, b.label))
            .map((el) => (
              <Option key={el.value} value={el.label}>
                {el.label}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item noStyle name="analysisStatus">
        <Select
          mode="multiple"
          placeholder="Status"
          onChange={onStatus}
          className={styles.searchStatusFilter}
        >
          {_.clone(analysisStatus)
            .sort((a, b) => Helpers.compare(a.label, b.label))
            .map((el) => (
              <Option key={el.key} value={el.key}>
                {el.label}
              </Option>
            ))}
        </Select>
      </Form.Item>
      {isApprove && (
        <Form.Item noStyle name="analysisExecutor">
          <Select
            className={styles.searchFilter}
            showSearch
            style={{ width: "100%" }}
            placeholder="Executor"
            onChange={onExecutorChange}
            allowClear
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {_.clone(users.content)
              .sort((a, b) => Helpers.compare(a.userFullName, b.userFullName))
              .map((el) => (
                <Select.Option key={el.id}>{el.userFullName}</Select.Option>
              ))}
          </Select>
        </Form.Item>
      )}
      <Button type="primary" icon={<SearchOutlined />} onClick={onSearch}>
        Search
      </Button>
      <Button type="default" icon={<ClearOutlined />} onClick={onClear}>
        Clear
      </Button>
    </Form>
  );
};

export default AnalysisSearch;
