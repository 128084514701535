import { RootState } from "@redux/store";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Api from "@api/index";
import analysisApi from "@api/analysis";
// import { IPageableRequest } from "@api/api";
// import { defaultPageableContent } from "@api/const";

const initialState: analysisApi.IGetAnalysisType = [
  {
    id: "",
    actionTypeShortName: "",
    actionTypeFullName: "",
  },
];

export const getAnalysisType = createAsyncThunk(
  "analysis/getAnalysisType",
  async () => {
    const { data } = await Api.analysis.getAnalysisType();
    return data;
  }
);

export const analysisTypeSlice = createSlice({
  name: "analysisType",
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAnalysisType.fulfilled,
      (_, action: PayloadAction<analysisApi.IGetAnalysisType>) => {
        return action.payload;
      }
    );
  },
});

export const { clear } = analysisTypeSlice.actions;

export const analysisTypeSelector = (state: RootState) => state.analysisType;

export default analysisTypeSlice.reducer;
