import React, { useCallback, useEffect, useState } from "react";
import { PageHeader, Pagination, Select } from "antd";
import { pageTitles, statusConfig } from "@helpers/const";
import { useLocation, Link } from "react-router-dom";
import { Button, DatePicker, Table, Tag } from "antd";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { getTimesheet, timesheetsSelector } from "@redux/slices/timesheet";
import { defaultPageSize } from "@api/const";
import TimesheetApi from "@api/timesheet";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { RangeValue } from "rc-picker/lib/interface";
import { Moment } from "moment";
import styles from "./All.module.scss";
import { authSelector } from "@redux/slices/auth";
import { delegatedUserSelector } from "@redux/slices/delegatedUser";
import Hooks from "@helpers/hooks";
import { IPageableRequest } from "@api/api";
import Helpers from "@helpers/utils";

export interface IFilter {
  dateFrom?: string;
  dateTo?: string;
  timesheetStatus?: string;
  enterpriseId?: string;
}

const All = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { id } = useAppSelector(authSelector);
  const delegated = useAppSelector(delegatedUserSelector);
  const { content, totalElements } = useAppSelector(timesheetsSelector);
  const [tableData, setTableData] = useState<
    TimesheetApi.IGetResponseContent[]
  >([]);
  const [filters, setFilters] = useState<IFilter>({});
  const [pagination, setPagination] = useState<IPageableRequest>({
    page: 1,
    size: 10,
  });
  const Portal = Hooks.usePortal(document.getElementById("pageHeader"));

  useEffect(() => {
    setTableData(
      content
        .map((el) => ({
          ...el,
          key: el.id,
        }))
        .sort((a, b) =>
          Helpers.compare(b.timesheetDateStart, a.timesheetDateStart)
        )
    );
  }, [content, setTableData]);

  useEffect(() => {
    dispatch(
      getTimesheet({
        enterpriseId: delegated.id ? delegated.id : id,
        ...defaultPageSize,
      })
    );
  }, [dispatch, id, delegated]);

  const onStatusChange = (value: CheckboxValueType[]) => {
    if (value === undefined || !value.length) {
      setFilters((state) => ({
        ...state,
        timesheetStatus: "",
      }));
      return;
    } else {
      const timesheetStatus = value;

      setFilters((state) => ({
        ...state,
        timesheetStatus: timesheetStatus.toString(),
      }));
    }
  };

  const onDateChange = (dates: RangeValue<Moment>) => {
    setFilters((state) => ({
      ...state,
      dateFrom: dates?.[0]?.format("YYYY-MM-DD"),
      dateTo: dates?.[1]?.format("YYYY-MM-DD"),
    }));
  };

  const onSearch = () => {
    dispatch(
      getTimesheet({
        ...defaultPageSize,
        ...filters,
        enterpriseId: delegated.id ? delegated.id : id,
      })
    );
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "timesheetEnterpriseId",
      key: "timesheetEnterpriseId",
      render: (text: string, record: TimesheetApi.IGetResponseContent) => (
        <Link
          to={{
            pathname: "/my-timesheets/current",
            search: `?dateFrom=${record.timesheetDateStart}&dateTo=${record.timesheetDateEnd}`,
          }}
        >{`${text} ${record.timesheetDateStart}-${record.timesheetDateEnd}`}</Link>
      ),
    },
    {
      title: "Status",
      key: "timesheetStatus",
      dataIndex: "timesheetStatus",
      render: (status: "DRAFT" | "SUBMITED") => {
        return (
          <Tag color={statusConfig[status]} key={status}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Date from",
      dataIndex: "timesheetDateStart",
      key: "timesheetDateStart",
    },
    {
      title: "Date to",
      key: "timesheetDateEnd",
      dataIndex: "timesheetDateEnd",
    },
  ];

  const onPageChange = useCallback(
    (page: number, size: number) => {
      setPagination({
        page,
        size,
      });
      dispatch(
        getTimesheet({
          enterpriseId: delegated.id ? delegated.id : id,
          page: page - 1,
          size,
        })
      );
    },
    [delegated.id, dispatch, id]
  );

  return (
    <>
      <Portal>
        <PageHeader className={styles.header} title={pageTitles[pathname]} />
      </Portal>
      <div className={styles.searchPopoverWrapper}>
        <div className={styles.selectBox}>
          <b>Period</b>
          <DatePicker.RangePicker
            onChange={onDateChange}
            style={{ width: "100%" }}
          />
        </div>
        <div className={styles.checkBox}>
          <b>Type</b>
          <Select
            style={{ width: 120 }}
            allowClear
            placeholder="Type"
            onChange={onStatusChange}
            options={[
              {
                value: "SUBMITTED",
                label: "Submitted",
              },
              {
                value: "DRAFT",
                label: "Draft",
              },
            ]}
          />
          {/* <Checkbox.Group onChange={onStatusChange}>
            <Space>
              <Checkbox value="SUBMITTED">Submitted</Checkbox>
              <Checkbox value="DRAFT">Draft</Checkbox>
            </Space>
          </Checkbox.Group> */}
        </div>
        <Button type="primary" onClick={onSearch}>
          Search
        </Button>
      </div>
      <div className={styles.tableWrapper}>
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false}
          bordered
        />
        <Pagination
          showSizeChanger
          className={styles.pagination}
          current={pagination.page}
          onChange={onPageChange}
          total={totalElements}
          pageSize={pagination.size}
        />
      </div>
    </>
  );
};

export default All;
