import React from "react";
import { Spin } from "antd";
import classNames from "classnames";
import styles from "./Loader.module.scss";

interface ILoader {
  isAbsolute?: boolean;
}

const Loader: React.FC<ILoader> = ({ isAbsolute }) => (
  <div
    className={classNames({
      [styles.spin]: true,
      [styles.absoluteSpin]: isAbsolute,
    })}
  >
    <Spin size="large" />
  </div>
);

export default Loader;
