import { AxiosResponse } from "axios";
import { IPageableRequest, IPageableResponse } from "./api";
import {
  analysisApprove,
  analysisReject,
  analysisSendToApprove,
  analysisType,
  analysisUpdate,
  analysisUrl,
  createAnalysis,
  createAtReport,
} from "./const";
import Http from "./https";
import ProjectsApi from "./projects";
import TasksApi from "./tasks";
import { mapObjectToSearchParams } from "@helpers/mapObjectToSearchParams";

namespace analysisApi {
  export interface IGetResponseContent {
    analysisRecordId: string;
    date: string;
    projectName: string;
    taskName: string;
    actionType: IAnalysisType;
    effort: number;
    description: string;
    status: string;
    rejectComment: string;
    analysisApprover: string;
    analysisExecutor: string;
  }

  export interface IGetResponse extends IPageableResponse {
    content: IGetResponseContent[];
  }

  export interface IPostData {
    analysisRecordId?: string;
    analysisResource: number;
    analysisActWork: number;
    analysisActionTypeId?: string;
    analysisDate: string;
    analysisDescription: string;
    analysisComment: string;
  }

  export interface IPostContent extends Array<IPostData> {}

  export interface IPostParams {
    dateStart: string;
    dateFinish: string;
  }

  export interface IRejectData {
    data: {
      id: string;
      rejectComment: string;
    }[];
    dates: IPostParams;
  }

  export interface IPostRequest {
    content: IPostContent;
    dates: IPostParams;
  }

  export interface IPatchToApprove {
    data: string[];
    dates: IPostParams;
  }

  export interface IAnalysis {
    analysisRecordId: string; // required
    analysisResource: TasksApi.IResource;
    analysisActWork: number; //required
    analysisActionType: IAnalysisType;
    analysisDate: string; // required
    analysisDescription: string; // required
    analysisComment: string; // optional
    analsysisStatus: string; // required
    analysisApprover: string; // optional
    project: ProjectsApi.IResponseContent;
    task: TasksApi.IGeResponseContent;
  }

  export interface IAnalysisResponse extends Array<IAnalysis> {}

  export interface IGetRequest extends IPageableRequest {
    startDate: string;
    endDate: string;
    projectId?: string;
    analysisExecutor?: string;
    analysisApprover?: string;
    analysisActionType?: string;
    taskId?: string;
    status?: string[];
  }

  export interface IPatchContent extends Array<string> {}

  export interface IPatchRequest {
    dates: IPostParams;
    content: IPatchContent;
  }

  export interface IGetAnalysisType extends Array<IAnalysisType> {}

  export interface IAnalysisType {
    id: string;
    actionTypeShortName: string;
    actionTypeFullName: string;
  }

  export interface ICreateReportRequest {
    dateFrom: string;
    dateTo: string;
  }

  export interface IDeleteItemsRequest {
    items: string[];
    dates: IPostParams;
  }

  export const get = async (
    data: IGetRequest
  ): Promise<AxiosResponse<IGetResponse>> => {
    return await Http.Private.get(mapObjectToSearchParams(analysisUrl, data));
  };

  export const getAnalysisType = async (): Promise<
    AxiosResponse<IGetAnalysisType>
  > => {
    return await Http.Private.get(analysisType);
  };

  export const patchToApprove = async ({
    data,
    dates,
  }: IPatchToApprove): Promise<AxiosResponse<IAnalysisResponse>> => {
    return await Http.Private.patch(
      `${analysisSendToApprove}?dateStart=${dates.dateStart}&dateFinish=${dates.dateFinish}`,
      data
    );
  };

  export const approve = async (
    data: IPatchRequest
  ): Promise<AxiosResponse<IAnalysisResponse>> => {
    return await Http.Private.patch(
      analysisApprove +
        `?dateStart=${data.dates.dateStart}&dateFinish=${data.dates.dateFinish}`,
      data.content
    );
  };

  export const patchReject = async ({
    data,
    dates,
  }: IRejectData): Promise<AxiosResponse<IAnalysisResponse>> => {
    return await Http.Private.patch(
      `${analysisReject}?dateStart=${dates.dateStart}&dateFinish=${dates.dateFinish}`,
      data
    );
  };

  export const putUpdate = async (
    data: IPostData[]
  ): Promise<AxiosResponse<IAnalysisResponse>> => {
    return await Http.Private.put(analysisUpdate, data);
  };

  export const post = async (data: IPostRequest) => {
    return await Http.Private.post(
      createAnalysis +
        `?dateStart=${data.dates.dateStart}&dateFinish=${data.dates.dateFinish}`,
      data.content
    );
  };

  export const createReport = async (
    data: ICreateReportRequest
  ): Promise<AxiosResponse<Blob>> => {
    return await Http.Private.post(createAtReport, data, {
      responseType: "blob",
    });
  };

  export const deleteItems = async ({ items, dates }: IDeleteItemsRequest) => {
    return await Http.Private.delete(
      `${analysisUrl}?dateStart=${dates.dateStart}&dateFinish=${dates.dateFinish}`,
      {
        data: items,
      }
    );
  };
}

export default analysisApi;
