import analysisApi from "./analysis";
import AuthApi from "./auth";
import externalApi from "./external";
import ProjectsApi from "./projects";
import TasksApi from "./tasks";
import TimesheetApi from "./timesheet";
import UsersApi from "./users";

namespace Api {
  export const users = UsersApi;
  export const auth = AuthApi;
  export const projects = ProjectsApi;
  export const timesheet = TimesheetApi;
  export const tasks = TasksApi;
  export const analysis = analysisApi;
  export const external = externalApi;
}

export default Api;
