import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "@redux/slices/auth";
import usersReducer from "@redux/slices/users";
import delegatedUserReducer from "@redux/slices/delegatedUser";
import selectedUserReducer from "@redux/slices/selectedUser";
import projectsReducer from "@redux/slices/projects";
import timesheetsReducer from "@redux/slices/timesheet";
import currentTimesheetsReducer from "@redux/slices/currentTimesheet";
import currentTaskReducer from "@redux/slices/currentTask";
import tasksReducer from "@redux/slices/tasks";
import myTasksReducer from "@redux/slices/myTasks";
import analysisReducer from "@redux/slices/analysis";
import analysisTypeReducer from "@redux/slices/analysisType";
import taskResourcesReducer from "@redux/slices/taskResources";
import externalReducer from "@redux/slices/external";

const rootReducer = {
  auth: authReducer,
  users: usersReducer,
  delegatedUser: delegatedUserReducer,
  selectedUser: selectedUserReducer,
  projects: projectsReducer,
  timesheets: timesheetsReducer,
  currentTimesheet: currentTimesheetsReducer,
  currentTask: currentTaskReducer,
  taskResources: taskResourcesReducer,
  tasks: tasksReducer,
  myTasks: myTasksReducer,
  analysis: analysisReducer,
  analysisType: analysisTypeReducer,
  external: externalReducer,
};

export default function createReducer(injectedReducers = {}) {
  const reducer = combineReducers({
    ...rootReducer,
    ...injectedReducers,
  });

  return reducer;
}
